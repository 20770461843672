import React, { useEffect } from "react"
import Rtstamp from "../../../Invoices/rtstamp.png"
import RSAstamp from "../../../Invoices/RSA_Stamp.PNG"



const CallForSignature = (props) => {
    const CallForSignatureFun = () => {
        if (props.invoiceNumber.includes("DXB") || props.invoiceNumber.includes("FZLLC") || props.invoiceNumber.includes("DMCC")) {
            return (
                <>
                    <img src="https://www.koenig-solutions.com/platinum/images/DubaiStamp.png" style={{ height: "50px", float: "right" }}></img>
                </>
            )
        }
        else if ((props.invoiceNumber.includes("KSL/US/"))) {
            return (<>
                <img src="https://www.koenig-solutions.com/platinum/images/USAStamp.jpg" style={{ height: "50px", float: "right" }}></img>
            </>
            )
        }
        else if ((props.invoiceNumber.includes("KSUK/"))) {
            return (<>
                <img src="https://www.koenig-solutions.com/platinum/images/LondonStamp.png" style={{ height: "50px", float: "right" }}></img>
            </>
            )
        }
        else if (props.invoiceNumber.includes("KSAUS/")) {
            return (<>
                <img src="https://www.koenig-solutions.com/platinum/images/AustraliaStamp.png" style={{ height: "50px", float: "right" }}></img>
            </>
            )
        }
        else if (props.invoiceNumber.includes("KSPL/SGP/")) {
            return (<>
                <img src="https://www.koenig-solutions.com/platinum/images/SingaporeStamp.jpg" style={{ height: "50px", float: "right" }}></img>
            </>
            )
        }
        else if (props.invoiceNumber.includes("KSPLNZ/")) {
            return (<>
                <img src="https://rms.koenig-solutions.com/webiner/KoenigNewZealand.jpg" style={{ height: "50px", float: "right" }}></img>
            </>
            )
        }
        else if (props.invoiceNumber.includes("KSSaudi/")) {
            return (<>
                <img src="https://rms.koenig-solutions.com/webiner/Saudi_Arabia_Stamp.jpg" style={{ height: "50px", float: "right" }}></img>
            </>
            )
        }
        else if (props.invoiceNumber.includes("KSGMBH/")) {
            return (<>
                <img src="https://rms.koenig-solutions.com/webiner/Germany_Stamp.jpg" style={{ height: "50px", float: "right" }}></img>
            </>
            )
        }
        else if (props.invoiceNumber.includes("KSMY/")) {
            return (<>
                <img src="https://rms.koenig-solutions.com/webiner/MalaysiaStamp.jpg" style={{ height: "50px", float: "right" }}></img>
            </>
            )
        }
        else if (props.invoiceNumber.includes("RSEXP") || props.invoiceNumber.includes("RTEXP")) {
            return (<>
                <img src={Rtstamp} style={{ height: "50px", float: "right" }}></img>
            </>
            )
        }
        else if (props.invoiceNumber.includes("RS2") || props.invoiceNumber.includes("RT2")) {
            return (<>
                <img src={Rtstamp} style={{ height: "50px", float: "right" }}></img>
            </>
            )
        }
        else if (props.invoiceNumber.includes("KS-SA")) {
            return (<>
                <img src={RSAstamp} style={{ height: "50px", float: "right" }}></img>
            </>
            )
        }
        else if (props.invoiceNumber.includes("KSNL")) {
            return (<>
                <img src="https://www.koenig-solutions.com/platinum/images/NeitherLandsStamp.png" style={{ height: "50px", float: "right" }}></img>
            </>
            )
        }
        else {
            return (
                <>
                    <img src="https://www.koenig-solutions.com/platinum/images/Signature-Bharat.png" style={{ height: "50px", float: "right" }} />
                </>
            )
        }
    }

    useEffect(() => {
        CallForSignatureFun();
    }, [])


    return <> {CallForSignatureFun()}
    </>
}

export default CallForSignature







