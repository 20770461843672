import React, { useState, useEffect } from "react";
import KoeAddressDetails from "./Compontents/elements/KoeAddressDetails.js";
import CallForLogo from "./Compontents/elements/CallForLogo.js";
import CallForSignature from "./Compontents/elements/CallForSignature.js";
import CallForBank from "./Compontents/elements/CallForBank.js";
import "./Compontents/main.css";
import "./Compontents/invoice2.css";
import PaymentTerms from "./Compontents/elements/PaymentTerms.js";
import DeliveryTerms from "./Compontents/elements/DeliveryTerms.js";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import nextId from "react-id-generator";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@material-ui/core";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import { Button, Icon, Label } from 'semantic-ui-react'
import { Dropdown } from "semantic-ui-react";
import CurrencyDropdown from "./Compontents/elements/CurrencyDropdown.js";
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Popup, Input } from 'semantic-ui-react';



const queryString = require("query-string");
const SecondStep = () => {
  const queryURL = queryString.parseUrl(window.location.href);
  const [invid, setinvid] = useState(queryURL.query.invid);
  const [isEdit, set_isEdit] = useState(queryURL.query.Edit);
  const [show_list, set_show_list] = React.useState("none");
  const [searchResults, setSearchResults] = React.useState([]);
  const [buyerName, set_buyerName] = React.useState("");
  const [buyerId, set_buyerId] = useState("");

  //const [CountData, Set_CountData] = useState(0);  //  hook define

  const [invoiceData, Set_invoiceData] = useState([]);
  const [BankData, Set_BankData] = useState([]);
  const [Currency, set_Currency] = useState("");
  const [Currency1, set_Currency1] = useState("");
  const [invoiceNo, Set_invoiceNo] = useState("");
  const [invoiceId, Set_invoiceId] = useState("");
  const [InvoiceDate, set_InvoiceDate] = useState("");
  const [BuyerOrderNo, set_BuyerOrderNo] = useState("");
  const [POAmount, set_POAmount] = useState("");
  const [OrderDate, set_OrderDate] = useState("");
  const [OtherRef, set_OtherRef] = useState("");
  const [payterms, set_payterms] = useState(0);
  const [serviceData, set_serviceData] = useState([]);
  const [ICGST, set_ICGST] = useState(0);
  const [ICGST1, set_ICGST1] = useState(1);
  const [GST, set_GST] = useState(0);
  const [GST1, set_GST1] = useState(1);
  const [SCGST, set_SCGST] = useState(0);
  const [SCGST1, set_SCGST1] = useState(1);
  const [GCGST, set_GCGST] = useState(0);
  const [GCGST1, set_GCGST1] = useState(1);
  const [VAT, set_VAT] = useState(0);
  const [VAT1, set_VAT1] = useState(1);
  const [WHT, set_WHT] = useState(0);
  const [WHT1, set_WHT1] = useState(1);
  const [isSez, set_isSez] = useState(false);
  const [WHTRate, set_WHTRate] = useState();
  const [DueDate, set_DueDate] = useState();
  const [BankComm, set_BankComm] = useState(0);
  const [BankComm1, set_BankComm1] = useState(0);
  const [GrandTotal, set_GrandTotal] = useState(0);
  const [GrandTotal1, set_GrandTotal1] = useState(0);
  const [bankRequired, set_bankRequired] = useState(false);
  const [clientCountryRequired, set_clientCountryRequired] = useState(false);
  const [KoeName, set_KoeName] = useState("");
  const [KoeAddress, set_KoeAddress] = useState("");
  const [Loading, set_Loading] = useState(false);
  const [gstOnOtherServices, set_gstOnOtherServices] = useState(false);
  const [TACOST, set_TACOST] = useState(0);
  const [DACOST, set_DACOST] = useState(0);
  const [FlightCost, set_FlightCost] = useState(0);
  const [HotelCost, set_HotelCost] = useState(0);
  const [MiscCost, set_MiscCost] = useState(0);
  const [VisaCost, set_VisaCost] = useState(0);
  const [buyerAddress, set_buyerAddress] = React.useState("");
  const [bankId, set_bankId] = React.useState(0);
  const [SecondCurrency, set_SecondCurrency] = useState("");
  const [IsRayontara, set_IsRayontara] = useState(0);

  const [TACOST_Desc, set_TACOST_Desc] = useState("TA Cost");
  const [DACOST_Desc, set_DACOST_Desc] = useState("DA Cost");
  const [FlightCost_Desc, set_FlightCost_Desc] = useState("Flight Cost");
  const [HotelCost_Desc, set_HotelCost_Desc] = useState("Hotel Cost");
  const [MiscCost_Desc, set_MiscCost_Desc] = useState("Misc Cost");
  const [VisaCost_Desc, set_VisaCost_Desc] = useState("Visa Cost");
  const [AdvancePayment, set_AdvancePayment] = useState(false);
  const [AddColumnForCurrecny, set_AddColumnForCurrecny] = useState(false);
  let d = localStorage.getItem("emp_data");
  const [ModifiedBy, set_ModifiedBy] = useState(JSON.parse(d).user_id);
  const [BillingLocation, set_BillingLocation] = useState("");
  const [IsMultpleInvoice, set_IsMultpleInvoice] = useState(0);
  const [noofpaxininvoice, set_noofpaxininvoice] = useState(0);
  const [DeliveryType, set_DeliveryType] = useState(0);

  const [InvoiceName, set_InvoiceName] = useState("TAX INVOICE");
  const [isSales, set_isSales] = useState(false)
  const [StdId, set_StdId] = useState(0);
  const [CityId, set_CityId] = useState(0);


  // let d = localStorage.getItem("emp_data");
  // const [userid,set_userid] = useState(JSON.parse(d).user_id);
  const axios = require("axios");
  let rowno = 0;
  let resu = '0';

  function replaceAll(string, search, replace) {

    string = string.replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f")
    //    .replace("#","\\#")
    return string.split(search).join(replace);
  }

  //#region *******************All useeffect*****************************
  useEffect(() => {
    callForInvoiceShow();
    callForBuyerName();
    callForPaymentTerms();
    callForDeliveryTerms();

    console.log("testingggg ", JSON.parse(d).user_id)
    if ((JSON.parse(d).user_id == "138") || (JSON.parse(d).user_id == "8948") || (JSON.parse(d).user_id == "167") || (JSON.parse(d).user_id == "14633") || (JSON.parse(d).user_id == "7940") || (JSON.parse(d).user_id == "14731")) {
      set_isSales(false)
    } else {
      set_isSales(true)
    }
  }, []);
  useEffect(() => {

    callForGrandTotal();

  }, [serviceData, BankComm, WHT, SCGST, GCGST, GST, ICGST, VAT, isSez, TACOST, DACOST, FlightCost, HotelCost, VisaCost, MiscCost, gstOnOtherServices]);
  //#endregion
  //#region ********************All Function*********************************************
  const callForInvoiceShow = () => {
    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=15&invid=" + invid)
      .then(function (response) {
        console.log(response.data);
        Set_invoiceData(response.data);
        response.data.map((i, index) => {
          setHooks(i, index);
        });
      })
      .catch(function (error) {
        alert(error);
      });

    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=12&invid=" + invid)
      .then(function (response) {
        Set_BankData(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  };

  async function insertData1(jsonDT) {



    // if (localStorage.getItem("emp_id") == "1955") {
    //   // alert(jsonDT)
    //   console.log("/Invoice/proc_NewInvoiceCreate1?type=28" +
    //     "&jsonDT=" +
    //     jsonDT +
    //     "")
    // }




    debugger;
    await axios.get(
      "/Invoice/proc_NewInvoiceCreate1?type=28" +
      "&jsonDT=" +
      jsonDT +
      ""
    ).catch(function (error) {
      alert(error)
      console.log(error)
    });
  }





  function checkApprovalCodeValid() {
    var code = document.getElementById('txtCode').value
    //alert(code)
    debugger
    axios.get(
      "/Invoice/proc_Check_ApprovalCode?code=" + code + ""
    )
      .then(function (response) {
        var res = response.data;
        if (res === 'valid_but_used') {
          alert('The Code has been used')
          return
        }
        else if (res === 'invalid') {
          alert('The Code is Invalid')
          return
        }
        else if ('valid_and_not_used') {

          debugger;
          let clientcountry = clientCountryRequired == false ? 0 : 1;
          let gstOnOtherServices1 = gstOnOtherServices == false ? 0 : 1;
          let AdvancePayment1 = AdvancePayment == false ? 0 : 1;
          let AddColumnForCurrecny1 = AddColumnForCurrecny == false ? 0 : 1;


          callForGrandTotal();
          let sez = isSez == false ? 0 : 1;
          let ICGSTVal = ICGST == 0 ? GST : ICGST;
          let mst_out_data = {
            AdvancePayment: AdvancePayment1,
            isSecondCurrecnyReq: AddColumnForCurrecny1,
            GSTApply: gstOnOtherServices1,
            InvoiceNo: invoiceNo,
            BuyerName: replaceAll(replaceAll(replaceAll(replaceAll(buyerName, "#", "_sharp_"), "&", "_and_"), "%", ""), "\n", " "),
            BuyerAddress: replaceAll(replaceAll(replaceAll(replaceAll(buyerAddress, "#", "_sharp_"), "&", "_and_"), "%", ""), "\n", " "),
            CorporateId: buyerId,
            InvBankId: bankId,
            InvVatAmnt: VAT,
            WHT: WHT,
            InvICGSTAmt: ICGSTVal,
            InvSGSTAmt: SCGST,
            InvCGSTAmt: GCGST,
            isSez: isSez,
            InvAmount: GrandTotal,
            BuyerOrderNo: BuyerOrderNo,
            POAmount: POAmount,
            InvoiceDate: InvoiceDate,
            OrderDate: OrderDate,
            OtherRef: OtherRef,
            PaymentTerms: payterms,
            TABillAmt: TACOST,
            DABillAmt: DACOST,
            MiscBillAmt: MiscCost,
            VisaBillAmt: VisaCost,
            HotelCost: HotelCost,
            TripAmt: FlightCost,
            TABillDesc: TACOST_Desc,
            DABillDesc: DACOST_Desc,
            MiscDesc: MiscCost_Desc,
            VisaDesc: VisaCost_Desc,
            HotelCostDesc: HotelCost_Desc,
            TripDesc: FlightCost_Desc,
            invid: invid,
            NoClientCountryRequired: clientcountry,
            ModifiedBy: ModifiedBy,

            InvVatAmnt1: VAT1,
            WHT1: WHT1,
            InvICGSTAmt1: ICGST1,
            InvSGSTAmt1: SCGST1,
            InvCGSTAmt1: GCGST1,
            InvAmount1: GrandTotal1,
            Currency1: Currency1,
            DeliveryType: DeliveryType,
            StdId: StdId,
            CityId: CityId
          }
          debugger;
          insertData1(JSON.stringify(mst_out_data));
          set_Loading(true);
          let tbldatas = [];
          serviceData.map((item) => {
            if (item.Description !== "" && item.InvAmt > 0) {
              let new_Entry = {
                invid: item.invid,
                UID: item.UID,
                Sno: item.Sno,
                Description: replaceAll(replaceAll(replaceAll(item.Description, "#", "_sharp_"), "&", "_and_"), "%", ""),
                StartDate: item.StartDate,
                EndDate: item.EndDate,
                NoOfParticipate: item.NoOfParticipate,
                NoOfWorkingDays: item.NoOfWorkingDays,
                InvAmt: item.InvAmt,
                QuotationId: item.QuotationId,
                InvAmt1: item.InvAmt1,
                BankComm: item.BankComm,
                NoBankRequired: bankRequired,
                isEdit: queryURL.query.Edit
              }

              tbldatas.push(new_Entry);
            }
          }
          )
          insertData2(tbldatas);

          axios.get(
            "/Invoice/proc_Update_ApprovalCode?code=" + code + "")
        }

      });
  }
  async function checkBulkApprovalClient() {
    //alert(StdId)
    debugger
    axios.get(
      "/Invoice/proc_Check_BulkApprovalClient?code=" + StdId + ""
    )
      .then(function (response) {
        resu = response.data;

      }
      )
  }

  async function checkStatusInvoice(jsonDT) {
    checkBulkApprovalClient();
    let Exist = 0;
    debugger
    await axios.get(
      "/Invoice/proc_NewInvoiceCreate1?type=30" +
      "&jsonDT=" +
      jsonDT +
      ""
    )
      .then(function (response) {
        response.data.map((i, index) => {
          let { status } = i
          if (status === "Exist") {
            Exist++;
          }
        });
      });



    //alert(DeliveryType)
    if ((Exist > 0) && (isEdit != 1)) {
      alert("Invoice Number Already Exist")
    }
    else if (DeliveryType == 0) {
      alert("Please Select Terms of Delivery")
    }
    else if ((resu != 1) && (isEdit != 1) && (
      payterms === 4
      || payterms === 288
      || payterms === 185
      || payterms === 130
      || payterms === 258
      || payterms === 163
      || payterms === 308
      || payterms === 206
      || payterms === 97
      || payterms === 35
      || payterms === 282
      || payterms === 6
      || payterms === 135
      || payterms === 98
      || payterms === 307
      || payterms === 133
      || payterms === 178
      || payterms === 162
      || payterms === 15
      || payterms === 208
      || payterms === 16
      || payterms === 228
      || payterms === 205
      || payterms === 7
      || payterms === 207
      || payterms === 281
      || payterms === 213
      || payterms === 155
      || payterms === 295
      || payterms === 151
      || payterms === 245
      || payterms === 293
      || payterms === 250
      || payterms === 316
      || payterms === 241)) {
      callForApprovalAlert()
    }
    else {
      debugger;
      let clientcountry = clientCountryRequired == false ? 0 : 1;
      let gstOnOtherServices1 = gstOnOtherServices == false ? 0 : 1;
      let AdvancePayment1 = AdvancePayment == false ? 0 : 1;
      let AddColumnForCurrecny1 = AddColumnForCurrecny == false ? 0 : 1;


      callForGrandTotal();
      let sez = isSez == false ? 0 : 1;
      let ICGSTVal = ICGST == 0 ? GST : ICGST;
      let mst_out_data = {
        AdvancePayment: AdvancePayment1,
        isSecondCurrecnyReq: AddColumnForCurrecny1,
        GSTApply: gstOnOtherServices1,
        InvoiceNo: invoiceNo,
        BuyerName: replaceAll(replaceAll(replaceAll(replaceAll(buyerName, "#", "_sharp_"), "&", "_and_"), "%", ""), "\n", " "),
        BuyerAddress: replaceAll(replaceAll(replaceAll(replaceAll(buyerAddress, "#", "_sharp_"), "&", "_and_"), "%", ""), "\n", " "),
        CorporateId: buyerId,
        InvBankId: bankId,
        InvVatAmnt: VAT,
        WHT: WHT,
        InvICGSTAmt: ICGSTVal,
        InvSGSTAmt: SCGST,
        InvCGSTAmt: GCGST,
        isSez: isSez,
        InvAmount: GrandTotal,
        BuyerOrderNo: BuyerOrderNo,
        POAmount: POAmount,
        InvoiceDate: InvoiceDate,
        OrderDate: OrderDate,
        OtherRef: OtherRef,
        PaymentTerms: payterms,
        TABillAmt: TACOST,
        DABillAmt: DACOST,
        MiscBillAmt: MiscCost,
        VisaBillAmt: VisaCost,
        HotelCost: HotelCost,
        TripAmt: FlightCost,
        TABillDesc: TACOST_Desc,
        DABillDesc: DACOST_Desc,
        MiscDesc: MiscCost_Desc,
        VisaDesc: VisaCost_Desc,
        HotelCostDesc: HotelCost_Desc,
        TripDesc: FlightCost_Desc,
        invid: invid,
        NoClientCountryRequired: clientcountry,
        ModifiedBy: ModifiedBy,

        InvVatAmnt1: VAT1,
        WHT1: WHT1,
        InvICGSTAmt1: ICGST1,
        InvSGSTAmt1: SCGST1,
        InvCGSTAmt1: GCGST1,
        InvAmount1: GrandTotal1,
        Currency1: Currency1,
        DeliveryType: DeliveryType
        , StdId: StdId
      }

      debugger;
      insertData1(JSON.stringify(mst_out_data));
      set_Loading(true);
      let tbldatas = [];
      serviceData.map((item) => {
        if (item.Description !== "" && item.InvAmt > 0) {
          let new_Entry = {
            invid: item.invid,
            UID: item.UID,
            Sno: item.Sno,
            Description: replaceAll(replaceAll(replaceAll(item.Description, "#", "_sharp_"), "&", "_and_"), "%", ""),
            StartDate: item.StartDate,
            EndDate: item.EndDate,
            NoOfParticipate: item.NoOfParticipate,
            NoOfWorkingDays: item.NoOfWorkingDays,
            InvAmt: item.InvAmt,
            QuotationId: item.QuotationId,
            InvAmt1: item.InvAmt1,
            BankComm: item.BankComm,
            NoBankRequired: bankRequired,
            isEdit: queryURL.query.Edit
          }

          tbldatas.push(new_Entry);
        }
      }
      )
      insertData2(tbldatas);
    }
  }


  // async function insertData3(jsonDT) {
  //  debugger;
  //  let { data } = await axios.get(
  //    "/Invoice/proc_NewInvoiceCreate1?type=35"+      
  //      "&jsonDT=" +
  //      jsonDT   +      
  //      ""
  //  ); 
  //  window.location.href = "/InvoiceThirdStep?invid=" + invid;
  // }



  async function insertData2(jsonDT) {
    debugger
    var objPost = {
      type: "27",
      jsonDT: jsonDT
    }

    // if(localStorage.getItem("emp_id")=="1955"){
    //   //debugger
    //   //alert(jsonDT)
    //   console.log(objPost)
    //   console.log("/Invoice/proc_NewInvoiceCreate2"+ JSON.parse(objPost))
    // }    

    await axios.post("/Invoice/proc_NewInvoiceCreate2", JSON.parse(JSON.stringify(objPost))).catch(function (error) {
      alert(error)
      console.log(error)
    });
    debugger
    ///set_Loading(false);
    window.location.href = "/InvoiceThirdStep?invid=" + invid;
  }


  const ExtraServiceSection = () => {
    if (invoiceNo.includes("GST")) {
      return (
        <>
          <tr>
            <td colSpan="6">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={gstOnOtherServices}
                    onChange={(e) => {
                      if (e.target.checked) set_gstOnOtherServices(true);
                      else set_gstOnOtherServices(false);
                    }}
                  />
                }
                label="GST Applicable on TA/DA/Flight/Hotel Cost"
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <span>
                <input type="text" value={TACOST_Desc} onChange={(e) =>
                  set_TACOST_Desc((e.target.value))} />
                <input type="text" value={DACOST_Desc} onChange={(e) =>
                  set_DACOST_Desc((e.target.value))} />   <br />
                <input type="text" value={FlightCost_Desc} onChange={(e) =>
                  set_FlightCost_Desc((e.target.value))} /><br />
                <input type="text" value={HotelCost_Desc} onChange={(e) =>
                  set_HotelCost_Desc((e.target.value))} />   <br />
                <input type="text" value={MiscCost_Desc} onChange={(e) =>
                  set_MiscCost_Desc((e.target.value))} /> <br />
                <input type="text" value={VisaCost_Desc} onChange={(e) =>
                  set_VisaCost_Desc((e.target.value))} /><br />
              </span>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <span></span>
              <span>
                <input type="number" style={{ width: "60px" }} value={TACOST} onChange={(e) =>
                  set_TACOST(callForHandelNullValues(e.target.value))} />
                <input type="number" style={{ width: "60px" }} value={DACOST} onChange={(e) =>
                  set_DACOST(callForHandelNullValues(e.target.value))} />
                <input type="number" style={{ width: "60px" }} value={FlightCost} onChange={(e) =>
                  set_FlightCost(callForHandelNullValues(e.target.value))} />
                <input type="number" style={{ width: "60px" }} value={HotelCost} onChange={(e) =>
                  set_HotelCost(callForHandelNullValues(e.target.value))} />
                <input type="number" style={{ width: "60px" }} value={MiscCost} onChange={(e) =>
                  set_MiscCost(callForHandelNullValues(e.target.value))} />
                <input type="number" style={{ width: "60px" }} value={VisaCost} onChange={(e) =>
                  set_VisaCost(callForHandelNullValues(e.target.value))} />
              </span>
            </td>
          </tr>
        </>
      )
    }
  }




  const callForGrandTotal = () => {
    var grandT = 0;
    serviceData.map((i) => {
      let { InvAmt } = i;
      grandT =
        parseFloat(isNaN(grandT) ? 0 : grandT) +
        parseFloat(InvAmt == null || isNaN(InvAmt) || InvAmt == "" ? 0 : InvAmt);
    });

    if (isSez == true || isSez == 1) {
      set_ICGST(0);
      set_SCGST(0);
      set_GCGST(0);
      set_WHT(0);
      set_VAT(0);
      set_GST(0);
    }

    if (gstOnOtherServices == true) {
      var taxableValue = grandT
        + parseFloat(GST == null || isNaN(GST) ? 0 : GST)
        + parseFloat(TACOST == null || isNaN(TACOST) ? 0 : TACOST)
        + parseFloat(DACOST == null || isNaN(DACOST) ? 0 : DACOST)
        + parseFloat(HotelCost == null || isNaN(HotelCost) ? 0 : HotelCost)
        + parseFloat(VisaCost == null || isNaN(VisaCost) ? 0 : VisaCost)
        + parseFloat(MiscCost == null || isNaN(MiscCost) ? 0 : MiscCost)
        + parseFloat(FlightCost == null || isNaN(FlightCost) ? 0 : FlightCost)
      if (ICGST > 0) {
        // alert(ICGST)
        // alert(" invoiceNo : "+invoiceNo)
        if (invoiceNo.includes("KSPL/SGP")) {
          set_ICGST(parseFloat(taxableValue * 0.05))
        }
        else {
          set_ICGST(parseFloat(taxableValue * 0.18))
        }
      }
      else if (SCGST > 0) {
        set_SCGST(parseFloat(taxableValue * 0.09))
        set_GCGST(parseFloat(taxableValue * 0.09))
      }
    }
    else {
      var taxableValue = grandT;
      if (ICGST > 0) {
        // alert(ICGST)
        //alert(" invoiceNo : "+invoiceNo)
        if (invoiceNo.includes("KSCAD")) {
          set_ICGST(parseFloat(taxableValue * 0.05))
        }
        else {
          set_ICGST(parseFloat(taxableValue * 0.18))
        }
      }
      else if (SCGST > 0) {
        set_SCGST(parseFloat(taxableValue * 0.09))
        set_GCGST(parseFloat(taxableValue * 0.09))
      }
      else if (VAT > 0) {
        if (BillingLocation == 5 || BillingLocation == 796) {
          set_VAT(parseFloat(taxableValue * 0.05))
        }
        if (BillingLocation == 798) {
          set_VAT(parseFloat(taxableValue * 0.15))
        }
        if (BillingLocation == 882) {
          set_VAT(parseFloat(taxableValue * 0.19))
        }
      }
    }
    var final = parseFloat(grandT == null || isNaN(grandT) ? 0 : grandT) +
      parseFloat(VAT == null || isNaN(VAT) ? 0 : VAT) +
      parseFloat(WHT == null || isNaN(WHT) ? 0 : WHT) +
      parseFloat(ICGST == null || isNaN(ICGST) ? 0 : ICGST) +
      parseFloat(SCGST == null || isNaN(SCGST) ? 0 : SCGST) +
      parseFloat(GCGST == null || isNaN(GCGST) ? 0 : GCGST) +
      parseFloat(GST == null || isNaN(GST) ? 0 : GST) +
      parseFloat(TACOST == null || isNaN(TACOST) ? 0 : TACOST) +
      parseFloat(DACOST == null || isNaN(DACOST) ? 0 : DACOST) +
      parseFloat(FlightCost == null || isNaN(FlightCost) ? 0 : FlightCost) +
      parseFloat(VisaCost == null || isNaN(VisaCost) ? 0 : VisaCost) +
      parseFloat(HotelCost == null || isNaN(HotelCost) ? 0 : HotelCost) +
      parseFloat(MiscCost == null || isNaN(MiscCost) ? 0 : MiscCost) +
      parseFloat(
        BankComm == null || isNaN(BankComm) || BankComm == undefined
          ? 0
          : BankComm
      )

    set_GrandTotal(final);
  };




  const [city, set_city] = React.useState([]);

  useEffect(() => {
    get_city();
  }, []);

  function get_city() {
    axios
      .get("/Invoice/proc_ForBank?type=23")
      .then(function (response) {
        set_city(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  const callForBankDropDown = () => {
    if (bankId > 0) {
      return (
        <span>
          <Dropdown
            placeholder="Select Bank"
            openOnFocus={false}
            onChange={(e, { value }) => {
              set_bankId(value)
            }}
            defaultValue={bankId}
            selection
            options={city}
            clearable
            style={{ width: "150px" }}
          />
        </span>
      )
    }
    // else{
    //   return (
    //     <span>
    //     <Dropdown
    //       placeholder="Select Bank"
    //       openOnFocus={false}
    //       onChange={(e, { value }) => {
    //         set_bankId(value)
    //       }}
    //       selection
    //       options={city}
    //       clearable
    //       style={{ width: "150px" }}
    //     />
    //   </span>
    //   )
    // }

  };

  const callForAdditionalAdd = () => {
    if ((JSON.parse(d).user_id == "138") || (JSON.parse(d).user_id == "14633") || (JSON.parse(d).user_id == "167") || (JSON.parse(d).user_id == "8948") || (JSON.parse(d).user_id == "7940") || (JSON.parse(d).user_id == "14731"))
      return (
        <>
          <FormControlLabel control={<Checkbox checked={VAT} onChange={(e) => { if (e.target.checked) set_VAT(1); else set_VAT(0); }} />} label="VAT" />
          <FormControlLabel control={<Checkbox checked={ICGST} onChange={(e) => { if (e.target.checked) set_ICGST(1); else set_ICGST(0); }} />} label="IGST" />
          <FormControlLabel control={<Checkbox checked={GCGST} onChange={(e) => { if (e.target.checked) { set_SCGST(1); set_GCGST(1) } else { set_GCGST(0); set_SCGST(0); } }} />} label="CSGT/SGST" />
          <FormControlLabel control={<Checkbox checked={WHT} onChange={(e) => { if (e.target.checked) set_WHT(1); else set_WHT(0); }} />} label="WHT" />
          <FormControlLabel control={<Checkbox checked={GST} onChange={(e) => { if (e.target.checked) set_GST(1); else set_GST(0); }} />} label="GST" />
          <FormControlLabel control={<Checkbox checked={BankComm} onChange={(e) => { if (e.target.checked) set_BankComm(1); else set_BankComm(0); }} />} label="BankComm" />
          <FormControlLabel control={<Checkbox checked={AdvancePayment} onChange={(e) => { if (e.target.checked) set_AdvancePayment(1); else set_AdvancePayment(0); }} />} label="Advance Payment" />
          <FormControlLabel control={<Checkbox checked={AddColumnForCurrecny} onChange={(e) => { if (e.target.checked) set_AddColumnForCurrecny(1); else set_AddColumnForCurrecny(0); }} />} label="Add New Column" />
        </>
      )
  }

  const callForBankRequiredCheckBox = () => {
    //Account userid fixed
    if ((JSON.parse(d).user_id == "138") || (JSON.parse(d).user_id == "14633") || (JSON.parse(d).user_id == "167") || (JSON.parse(d).user_id == "8948") || (JSON.parse(d).user_id == "7940") || (JSON.parse(d).user_id == "14731"))
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={bankRequired}
              onChange={(e) => {
                if (e.target.checked) set_bankRequired(true);
                else set_bankRequired(false);
              }}
            />
          }
          label="No Bank Req."
        />
      );
  };
  const callForClientCountryRequiredCheckBox = () => {
    if ((JSON.parse(d).user_id == "138") || (JSON.parse(d).user_id == "14633") || (JSON.parse(d).user_id == "14731") || (JSON.parse(d).user_id == "167") || (JSON.parse(d).user_id == "8948") || (JSON.parse(d).user_id == "7940") || (JSON.parse(d).user_id == "8321"))
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={clientCountryRequired}
              onChange={(e) => {
                if (e.target.checked) set_clientCountryRequired(true);
                else set_clientCountryRequired(false);
              }}
            />
          }
          label="No Country Req."
        />
      );
  };
  const callForSEZCheckBox = () => {
    if ((JSON.parse(d).user_id == "138") || (JSON.parse(d).user_id == "14633") || (JSON.parse(d).user_id == "167") || (JSON.parse(d).user_id == "8948") || (JSON.parse(d).user_id == "7940") || (JSON.parse(d).user_id == "14731"))
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={isSez}
              onChange={(e) => {
                if (e.target.checked) set_isSez(true);
                else set_isSez(false);
              }}
            />
          }
          label="SEZ"
        />
      );
  };

  const callForTax = () => {

    if (isSez != 1) {
      return (
        <>

          {ICGST != 0 ? (
            <tr>
              <td></td>
              <td>{ICGST != 0 ? <span> {invoiceNo.includes("KSPLSGP") ? "GST 8%" : invoiceNo.includes("KSCAD/") ? "GST 5%" : "IGST 18%"}  </span> : null}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{ICGST != 0 ? (<input type="number" style={{ width: "60px" }} value={ICGST} onChange={(e) => set_ICGST(callForHandelNullValues(e.target.value))}></input>) : null} </td>

              {AddColumnForCurrecny ? (
                <td>{ICGST1 != 0 ? (<input type="number" style={{ width: "60px" }} value={ICGST1} onChange={(e) => set_ICGST1(callForHandelNullValues(e.target.value))}></input>) : null} </td>
              )
                : null}
            </tr>
          ) : null}

          {VAT != 0 ? (
            <tr>
              <td></td>
              <td>{VAT != 0 ? <span>VAT </span> : null}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{VAT != 0 ? (<input type="number" style={{ width: "60px" }} value={VAT} disabled={isSales} onChange={(e) => set_VAT(callForHandelNullValues(e.target.value))}></input>) : null} </td>
              {AddColumnForCurrecny ? (
                <td>{VAT1 != 0 ? (<input type="number" style={{ width: "60px" }} value={VAT1} disabled={isSales} onChange={(e) => set_VAT1(callForHandelNullValues(e.target.value))}></input>) : null} </td>
              )
                : null}
            </tr>
          ) : null}

          {
            WHT != 0 ? (
              <tr>
                <td></td>
                <td>{WHT != 0 ? <span>WHT </span> : null}</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{WHT != 0 ? (<input type="number" style={{ width: "60px" }} value={WHT} disabled={isSales} onChange={(e) => set_WHT(callForHandelNullValues(e.target.value))}></input>) : null} </td>
                {AddColumnForCurrecny ? (
                  <td>{WHT1 != 0 ? (<input type="number" style={{ width: "60px" }} value={WHT1} disabled={isSales} onChange={(e) => set_WHT1(callForHandelNullValues(e.target.value))}></input>) : null} </td>
                )
                  : null}
              </tr>
            ) : null
          }
          {
            SCGST != 0 || GCGST != 0 ? (

              <tr>
                <td></td>
                {SCGST != 0 || GCGST != 0 ? (<td><span> SGST  9% </span> <br /> <span> CGST 9% </span></td>) : null}
                <td></td>
                <td></td>
                <td></td>
                {SCGST != 0 || GCGST != 0 ? (
                  <td><span><input type="number" style={{ width: "60px" }} value={SCGST} disabled={isSales} onChange={(e) => set_SCGST(callForHandelNullValues(e.target.value))} ></input> <br /></span>
                    <span><input type="number" style={{ width: "60px" }} value={GCGST} disabled={isSales} onChange={(e) => set_GCGST((callForHandelNullValues(e.target.value)))}  ></input></span>
                  </td>) : null}
                {AddColumnForCurrecny ? (
                  <td><span><input type="number" style={{ width: "60px" }} value={SCGST1} disabled={isSales} onChange={(e) => set_SCGST1(callForHandelNullValues(e.target.value))} ></input> <br /></span>
                    <span><input type="number" style={{ width: "60px" }} value={GCGST1} disabled={isSales} onChange={(e) => set_GCGST1((callForHandelNullValues(e.target.value)))}  ></input></span>
                  </td>
                ) : null}


              </tr>


            ) : null
          }
          {GST != 0 ? (
            <tr>
              <td></td>
              <td>{GST != 0 ? <span> {BillingLocation == 35 && (CityId == 786) ? "HST 13%" :
                BillingLocation == 35 && (CityId == 811 || CityId == 812 || CityId == 813 || CityId == 814 || CityId == 815 || CityId == 817 || CityId == 818) ? "GST 5%" :
                  BillingLocation == 35 && (CityId == 819 || CityId == 820 || CityId == 821 || CityId == 822) ? "HST 15%" :
                    BillingLocation == 35 && (CityId == 816) ? "(GST & QST) 14.975%" :
                      BillingLocation == 507 ? "GST 9%" :
                        BillingLocation == 508 ? "GST 15%" :

                          "GST 10%"}  </span> : null}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{GST != 0 ? (<input type="number" style={{ width: "60px" }} disabled={isSales} value={GST} onChange={(e) => set_GST(callForHandelNullValues(e.target.value))}></input>) : null} </td>

              {AddColumnForCurrecny ? (
                <td>{GST1 != 0 ? (<input type="number" style={{ width: "60px" }} disabled={isSales} value={GST1} onChange={(e) => set_GST1(callForHandelNullValues(e.target.value))}></input>) : null} </td>
              ) : null}


            </tr>
          ) : null}
        </>
      );
    }


  };

  const callForSEZ = () => {
    set_SCGST(0);
    set_WHT(0);
    set_ICGST(0);
    set_GCGST(0);
    set_VAT(0);
    return <></>;
  };

  const callForBankComm = () => {
    return (
      <>
        <tr>
          <td></td>
          <td>
            <span>Bank Commission</span>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <input
              type="number"
              style={{ width: "60px" }}
              value={BankComm}
              onChange={(e) =>
                set_BankComm(callForHandelNullValues(e.target.value))
              }
            ></input>
          </td>
          {AddColumnForCurrecny ? (
            <td>
              <span>
                <input
                  type="number"
                  style={{ width: "60px" }}
                  value={BankComm1}
                  onChange={(e) =>
                    set_BankComm1(callForHandelNullValues(e.target.value))
                  }
                ></input>
              </span>
            </td>
          ) : null}

        </tr>
      </>
    );
  };
  const callForHandelNullValues = (num) => {
    if (num) {
      return num;
    } else {
      return 0;
    }
  };

  const callForLoader = () => {
    return (
      <Segment style={{ height: "100vh" }}>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    );
  };

  function search(d) {

    debugger;
    if (d.length != "") {
      set_show_list("");
      setSearchResults.value = null;
      let serstr = "";
      axios
        .get(
          "/Invoice/proc_NewInvoiceCreate?type=11&CurrIds=" + d.toString() + ""
        )
        .then(function (response) {
          debugger;
          let obj = [];
          response.data.map((value) => {
            obj.push({ text: value.text, id: value.id, CorporateName: value.CorporateName, Address: value.Address });
          });
          setSearchResults(obj);
        })
        .catch(function (error) {
          alert(error);
        })
        .then(function () { });
    }
  }

  const setHooks = (i, index) => {
    console.log("inv Amount");
    console.log(i.InvAmount1);

    set_GrandTotal1(i.InvAmount1)
    set_Currency1(i.Currency1)
    set_AddColumnForCurrecny(i.AddColumnForSecondCurrency)
    set_BillingLocation(i.BillingLocation);
    set_Currency(i.Currency);
    Set_invoiceNo(i.InvoiceNo);
    Set_invoiceId(i.INVId);
    set_InvoiceDate(i.InvoiceDate);
    set_BuyerOrderNo(i.BuyerOrderNo);
    set_POAmount(i.POAmount);
    set_OrderDate(i.OrderDate);
    set_OtherRef(i.OtherRef);
    set_IsMultpleInvoice(i.IsMultpleInvoice);
    rowno++;
    {
      AddColumnForCurrecny == true ? (
        set_serviceData((prevArray) => [
          ...prevArray,
          {
            invid: invid,
            UID: nextId(),
            Sno: rowno,
            Description: (i.Description),
            StartDate: i.StartDate,
            EndDate: i.EndDate,
            NoOfParticipate: i.NoOfParticipate,
            NoOfWorkingDays: i.NoOfWorkingDays,
            InvAmt: i.InvAmt,
            QuotationId: i.QuotationId,
            InvAmt1: i.InvAmt1,
            BankComm: BankComm
          },
        ])) :
        (
          set_serviceData((prevArray) => [
            ...prevArray,
            {
              invid: invid,
              UID: nextId(),
              Sno: rowno,
              Description: (i.Description),
              StartDate: i.StartDate,
              EndDate: i.EndDate,
              NoOfParticipate: i.NoOfParticipate,
              NoOfWorkingDays: i.NoOfWorkingDays,
              InvAmt: i.InvAmt,
              QuotationId: i.QuotationId,
              InvAmt1: 1,
              BankComm: BankComm
            },
          ])
        )
    }


    set_ICGST(i.InvICGSTAmt);
    set_GCGST(i.InvCGSTAmt);
    set_SCGST(i.InvSGSTAmt);
    set_WHT(i.whtAmt);
    set_VAT(i.InvVatAmnt);

    console.log("SGST");
    console.log(i.InvSGSTAmt);

    set_ICGST1(i.InvICGSTAmt1);
    set_GCGST1(i.InvCGSTAmt1);
    set_SCGST1(i.InvSGSTAmt1);
    set_WHT1(i.whtAmt1);
    set_VAT1(i.InvVatAmnt1);

    set_WHTRate(i.whtRate);
    set_isSez(i.isSez);

    set_DueDate(i.DueDate);
    if (i.BankComm > 0) {
      set_BankComm(i.BankComm);
    }
    set_bankRequired(i.NoBankRequired);
    set_clientCountryRequired(i.NoClientCountryRequired);
    Set_invoiceId(i.INVId);
    set_payterms(i.PTID);
    set_KoeAddress(i.KoeAddress);
    set_KoeName(i.KoenigName);
    set_buyerName(i.BuyerName);
    set_buyerAddress(i.BuyerAddress);
    set_buyerId(i.CorporateId);
    set_TACOST(i.TABillAmt)
    set_IsRayontara(i.IsRayontara)
    set_DACOST(i.DABillAmt)
    set_FlightCost(i.tripamt)
    set_MiscCost(i.MiscBillAmt)
    set_VisaCost(i.VisaBillAmt)
    set_HotelCost(i.HotelCost)
    set_GST(i.GST_Val);
    set_noofpaxininvoice(i.noofpaxininvoice)
    set_StdId(i.StdId)
    console.log("load", i.DeliveryType)
    set_DeliveryType(i.DeliveryType);
    set_CityId(i.CityId);
    //console.log(i.StdId)
    set_AdvancePayment(i.AdvancePayment);
    if (i.InvBankId > 0) {
      set_bankId(i.InvBankId);
    }
    //alert(i.CityId)
    if (i.GSTApply > 0) { (set_gstOnOtherServices(true)) }

    if (i.InvoiceNo != "") {
      if (i.InvoiceNo.includes("RSEXP") || i.InvoiceNo.includes("RTEXP")) {
        set_InvoiceName("EXPORT INVOICE")
      }
    }
    //  Set_CountData(rowno);

  };

  const handleChange = (e, uids, data) => {

    if (data != null) {
      let js_start = new Date(data.value);
      let FreshDate =
        js_start.getMonth() +
        1 +
        "/" +
        js_start.getDate() +
        "/" +
        js_start.getFullYear();

      set_serviceData(
        serviceData.map((item) =>
          item.UID === uids ? { ...item, [data.name]: FreshDate } : item
        )
      );
    } else {
      set_serviceData(
        serviceData.map((item) =>
          item.UID === uids
            ? { ...item, [e.target.name]: e.target.value }
            : item
        )
      );
    }
  };
  const addRow = () => {
    var rowno = 1;
    serviceData.map((i) => {
      rowno = i.Sno;
    });
    set_serviceData([
      ...serviceData,
      {
        invid: invid,
        UID: nextId(),
        Sno: rowno + 1,
        Description: "",
        StartDate: "",
        EndDate: "",
        NoOfParticipate: 0,
        NoOfWorkingDays: 0,
        InvAmt: 0,
        QuotationId: 0,
        BankComm: BankComm
      },
    ]);
  };

  async function uploadDataTest() {
    const tbldatas = [];
    serviceData.map((item) => {
      if (item.Description !== "" && item.InvAmt > 0) {
        tbldatas.push(item);
      }
    }
    )
    debugger;
    let { data } = await axios.get(
      "/Invoice/proc_NewInvoiceCreate1?type=27" +
      "&jsonDT=" +
      JSON.stringify(tbldatas) +
      ""
    );
  }

  const showDataHere = () => {
    serviceData.map((i) => {
      console.log(i);
      console.log(" BankComm : ", BankComm);
      console.log(" isSez ", isSez);
      console.log(" bankId : ", bankId);
      console.log(" vat : ", VAT);
    });
  };
  const callForPaymentTerms = () => {
    if (AdvancePayment == true) {
      return <p>Advance Payment</p>
    }
    else {
      if (payterms != 0)
        return <PaymentTerms passChildData={set_payterms} bydefault={payterms} />;
    }
  };

  const hideAlert = () => {
    ReactDOM.render("", document.getElementById('getSweetModel'));
  }
  const callForApprovalAlert = () => {
    ReactDOM.render(
      <Modal open={true} closeIcon={<Icon color="red" name="window close" />} onClose={() => hideAlert()}>
        <Modal.Header>Approval Required</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <div id="frmSection1">
              <table width="60%" >
                <tr id="trPaymentTerms">
                  <td>
                    <label >Enter Code:</label>   <input type="number" id="txtCode" name="txtCode" />
                  </td>
                  <td padding left="20px">
                    <button onClick={checkApprovalCodeValid}>
                      Apply Code
                    </button>
                  </td>
                </tr>

              </table>
              <br></br>
              <label> ** Special approval for payments terms greater than 60 days,Please enter approval code.</label>

              <br></br>

            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
      ,
      document.getElementById('getSweetModel'))



  };

  const callForDeliveryTerms = () => {
    console.log("delivery type", DeliveryType)
    if (DeliveryType != -1) {
      return <DeliveryTerms passChildData={set_DeliveryType} bydefault={DeliveryType} />;
    }
  }

  const callForBuyerName = () => {
    return (
      <span>
        <div
          className="divSearchBarDesktop"
          style={{ left: "-6px", width: "300px !important" }}
        >
          <TextareaAutosize
            aria-label="minimum height"
            rowsMin={3}
            onChange={(t) => {
              search(t.target.value);
              set_buyerName(t.target.value);
            }}
            value={buyerName}
            className="inputSearchDesktop"
            placeholder="Select Buyer Name"
          />
          <span className="SearchBoxItemDesktop">
            {searchResults.map((item) => {
              return (
                <span
                  key={item.id}
                  onClick={() => {
                    set_buyerId(item.id);
                    set_buyerAddress(item.Address.replace('&', ' and '))
                    set_buyerName(item.CorporateName)
                    set_show_list("none");
                    setSearchResults([]);
                  }}
                  className="listSearchDesktop"
                >
                  {item.text}
                </span>
              );
            })}
          </span>
        </div>
        <div>
          <TextareaAutosize
            aria-label="minimum height"
            rowsMin={3}
            onChange={

              (e) => { set_buyerAddress(e.target.value.replace('&', ' and ')) }
            }
            value={buyerAddress}
            className="form-control dropdownState"
            placeholder="Select Buyer Address"
          />
        </div>
      </span>
    );
  };

  //#endregion

  return (
    <div
      style={{
        overflowY: "scroll",
        height: "100vh",
        width: "100%",
        marginBottom: "2%",
      }}
    >
      <div id="getSweetModel" ></div>
      {Loading ? (
        callForLoader()
      ) : (
        <center>
          <table
            width="80%"
            border="0"
            cellSpacing="0"
            cellPadding="0"
            style={{
              margin: "10px",
              border: "1px solid black",
              padding: "40px",
            }}
          >
            <tbody>
              <tr>
                <td colSpan="2"></td>
              </tr>
              <tr>
                <td
                  width="60%"
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                >
                  {invoiceNo.includes("KSL-PI") ? (
                    <span
                      style={{
                        float: "right",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      PROFORMA INVOICE
                    </span>
                  ) : (
                    <span
                      style={{
                        float: "right",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      {InvoiceName}
                    </span>
                  )}

                </td>

                {IsRayontara == 1 ?
                  <td width="40%" style={{ padding: "2%", float: "right", fontSize: "18px" }}>
                    <strong> Rayontara Solutions </strong>
                  </td>
                  :
                  <td width="40%" style={{ padding: "2%" }}>
                    <CallForLogo invoiceNumber={invoiceNo} />
                  </td>
                }

              </tr>
              <tr>
                <td colSpan="2">
                  <table
                    width="100%"
                    border="0"
                    cellSpacing="0"
                    cellPadding="0"
                    style={{ border: "none" }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <table
                            width="100%"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ border: "none" }}
                          >
                            <tbody>
                              <tr>
                                <td width="349" valign="top">
                                  <KoeAddressDetails
                                    KoeName={KoeName}
                                    KoeAddress={KoeAddress}
                                    invoiceNumber={invoiceNo}
                                  />
                                  <table
                                    width="100%"
                                    border="0"
                                    cellSpacing="5"
                                    cellPadding="5"
                                    style={{
                                      borderBottom: "0 none",
                                      borderLeft: "0 none",
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td bgcolor="#fff">
                                          <label
                                            style={{ verticalAlign: "top" }}
                                          >
                                            Buyer
                                          </label>{" "}
                                          <br />
                                          {callForBuyerName()}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td
                                  width="351"
                                  valign="top"
                                  style={{
                                    height: "100%",
                                    borderTop: "solid 1px #ccc",
                                  }}
                                >
                                  <table
                                    width="100%"
                                    height="100%"
                                    border="0"
                                    cellSpacing="0"
                                    cellPadding="5"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          width="50%"
                                          bgcolor="#F2F2F2"
                                          className="borderB borderR"
                                        >
                                          Invoice No.
                                          <br />
                                          <strong>
                                            {
                                              ((JSON.parse(d).user_id == "138") || (JSON.parse(d).user_id == "14633") || (JSON.parse(d).user_id == "167") || (JSON.parse(d).user_id == "8948") || (JSON.parse(d).user_id == "7940") || (JSON.parse(d).user_id == "8321"))
                                                ? (<input type="text" value={invoiceNo} disabled onChange={(e) => { Set_invoiceNo(e.target.value) }}
                                                />) : (<label htmlFor="text">{invoiceNo}</label>
                                                )
                                            }
                                          </strong>

                                        </td>
                                        <td
                                          width="50%"
                                          bgcolor="#FFF"
                                          className="borderB borderR"
                                        >
                                          Dated
                                          <br />
                                          <strong>
                                            {
                                              ((JSON.parse(d).user_id == "138") || (JSON.parse(d).user_id == "14633") || (JSON.parse(d).user_id == "167") || (JSON.parse(d).user_id == "8948") || (JSON.parse(d).user_id == "7940") || (JSON.parse(d).user_id == "14731"))
                                                ? (<input type="text" value={InvoiceDate} onChange={(e) => { set_InvoiceDate(e.target.value) }}
                                                />) : (<label htmlFor="text">{InvoiceDate}</label>
                                                )
                                            }
                                          </strong>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          bgcolor="#FFF"
                                          className="borderB borderR"
                                        >
                                          Buyers Order No. (PO No.)
                                          <br />
                                          <strong>

                                            {
                                              ((JSON.parse(d).user_id == "138") || (JSON.parse(d).user_id == "14633") || (JSON.parse(d).user_id == "167") || (JSON.parse(d).user_id == "8948") || (JSON.parse(d).user_id == "7940") || (JSON.parse(d).user_id == "8321"))
                                                ? (<input type="text" value={BuyerOrderNo} onChange={(e) => { set_BuyerOrderNo(e.target.value) }}
                                                />) : (
                                                  <input type="text" value={BuyerOrderNo} onChange={(e) => { set_BuyerOrderNo(e.target.value) }}
                                                  />
                                                )
                                            }


                                            {/* <input
                                              type="text"
                                              value={BuyerOrderNo}
                                              onChange={(e) =>
                                                set_BuyerOrderNo(e.target.value)
                                              }
                                            /> */}
                                          </strong>
                                        </td>
                                        <td
                                          bgcolor="#FFF"
                                          className="borderB borderR"
                                        >
                                          Dated
                                          <br />
                                          <strong>
                                            {
                                              <input type="text" value={OrderDate} onChange={(e) => { set_OrderDate(e.target.value) }}
                                              />
                                            }
                                          </strong>
                                        </td>
                                      </tr>






                                      <tr>
                                        <td
                                          bgcolor="#FFF"
                                          className="borderB borderR"
                                        >
                                          PO Amount
                                          <br />
                                          <strong>

                                            {
                                              ((JSON.parse(d).user_id == "138") || (JSON.parse(d).user_id == "14633") || (JSON.parse(d).user_id == "167") || (JSON.parse(d).user_id == "8948") || (JSON.parse(d).user_id == "7940") || (JSON.parse(d).user_id == "14731"))
                                                ? (<input type="text" value={POAmount} onChange={(e) => { set_POAmount(e.target.value) }}
                                                />) : (
                                                  <input type="text" value={POAmount} onChange={(e) => { set_POAmount(e.target.value) }}
                                                  />
                                                )
                                            }



                                          </strong>
                                        </td>
                                        <td
                                          bgcolor="#FFF"
                                          className="borderB borderR"
                                        >

                                          PO Currency :
                                          <br />
                                          <strong>
                                            <CurrencyDropdown passChildData={set_Currency} />
                                          </strong>
                                        </td>
                                      </tr>





                                      <tr>
                                        <td
                                          bgcolor="#FFF"
                                          className="borderB borderR"
                                        >
                                          Other Reference(s)
                                          <br />
                                          <strong>
                                            {
                                              ((JSON.parse(d).user_id == "138") || (JSON.parse(d).user_id == "14633") || (JSON.parse(d).user_id == "167") || (JSON.parse(d).user_id == "8948") || (JSON.parse(d).user_id == "7940") || (JSON.parse(d).user_id == "8321"))
                                                ? (<input type="text" value={OtherRef} onChange={(e) => {
                                                  set_OtherRef(e.target.value)
                                                }}
                                                />) : (<label htmlFor="text">{OtherRef}</label>
                                                )
                                            }
                                          </strong>
                                        </td>
                                        <td
                                          bgcolor="#FFF"
                                          className="borderB borderR"
                                        >
                                          Payment Terms
                                          <br />
                                          <strong>
                                            {callForPaymentTerms()}
                                          </strong>
                                        </td>
                                      </tr>
                                      <tr
                                        bgcolor="#FFF"
                                        className="borderB borderR"
                                      >
                                        <td bgcolor="#F2F2F2">
                                          <span>Due Date</span>
                                          <br />
                                          {
                                            ((JSON.parse(d).user_id == "138") || (JSON.parse(d).user_id == "14633") || (JSON.parse(d).user_id == "167") || (JSON.parse(d).user_id == "8948") || (JSON.parse(d).user_id == "7940") || (JSON.parse(d).user_id == "14731"))
                                              ? (
                                                AdvancePayment == true ? (<span className="borderB borderR"> </span>) : (<input
                                                  name="txtInvoice"
                                                  type="text"
                                                  value={DueDate}
                                                  onChange={(e) =>
                                                    set_DueDate(e.target.value)
                                                  }
                                                />)
                                              ) : (
                                                AdvancePayment == true ? (<span className="borderB borderR"> </span>) : (
                                                  <label htmlFor="text">{DueDate}</label>
                                                ))
                                          }

                                        </td>
                                        {
                                          DeliveryType != -1 ? (
                                            <td
                                              bgcolor="#FFF"
                                              className="borderB borderR"
                                            >
                                              Terms of Delivery
                                              <br />
                                              <strong>
                                                {callForDeliveryTerms()}
                                              </strong>
                                            </td>
                                          ) : ""
                                        }

                                      </tr>
                                      <tr
                                        bgcolor="#FFF"
                                        className="borderB borderR"
                                      >
                                        <td colSpan="2">
                                          <span></span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table
                            width="100%"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                          >
                            <tbody>
                              <td>
                                {callForBankRequiredCheckBox()}
                                {callForClientCountryRequiredCheckBox()}
                                {callForSEZCheckBox()}
                                {callForAdditionalAdd()}
                                {callForBankDropDown()}
                              </td>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table
                            width="100%"
                            border="1"
                            cellSpacing="5"
                            cellPadding="5"
                            className="borderB bordera"
                          >
                            <tbody>
                              <tr className="borderB">
                                <td
                                  width="4%"
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                  className="borderB borderR"
                                >
                                  S.No.
                                </td>
                                <td
                                  width="30%"
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                  className="borderB borderR"
                                >
                                  Service Description
                                </td>{" "}
                                <td
                                  width="12%"
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                  className="borderB borderR"
                                >
                                  Start Date
                                </td>
                                <td
                                  width="12%"
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                  className="borderB borderR"
                                >
                                  End Date
                                </td>
                                <td
                                  width="12%"
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                  className="borderB borderR"
                                >
                                  Working Days/Participants
                                </td>
                                <td
                                  width="12%"
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                  className="borderB "
                                >
                                  Amount (
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bold",
                                      padding: "5px",
                                    }}
                                  >
                                    {Currency}
                                  </span>
                                  )
                                  <button value="+" onClick={() => addRow()}>
                                    +
                                  </button>
                                </td>

                                {AddColumnForCurrecny ? (<td
                                  width="12%"
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                  className="borderB "
                                >
                                  Amount (
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bold",
                                      padding: "5px",
                                    }}
                                  >

                                  </span>
                                  )
                                  <CurrencyDropdown passChildData={set_Currency1} />
                                </td>) : null}
                              </tr>
                              {/***************************************** service description row start**************************************************/}
                              {serviceData.map((datas, index) => (
                                <tr>
                                  <td className="borderR">{datas.Sno}</td>
                                  <td align="left" className="borderR">
                                    <span style={{ Display: "inline-block" }}>
                                      <TextareaAutosize
                                        aria-label="minimum height"
                                        id={datas.UID}
                                        rowsMin={2}
                                        value={datas.Description}
                                        name="Description"
                                        onChange={(e) =>
                                          handleChange(e, datas.UID, null)
                                        }
                                        style={{ width: "220px" }}
                                      />
                                      <br />
                                    </span>
                                  </td>
                                  <td className="borderR">
                                    {datas.StartDate ?
                                      (
                                        <span style={{ Display: "inline-block" }}>
                                          <SemanticDatepicker
                                            style={{ width: "50px !important" }}
                                            value={new Date(datas.StartDate)}
                                            name="StartDate"
                                            onChange={(e, data) =>
                                              handleChange(e, datas.UID, data)
                                            }
                                          /></span>
                                      ) :
                                      (
                                        <span style={{ Display: "inline-block" }}>
                                          <SemanticDatepicker
                                            style={{ width: "50px !important" }}
                                            placeholder={(datas.StartDate)}
                                            name="StartDate"
                                            onChange={(e, data) =>
                                              handleChange(e, datas.UID, data)
                                            }
                                          />
                                        </span>
                                      )
                                    }
                                  </td>
                                  <td className="borderR">
                                    {datas.EndDate ?
                                      (
                                        <span style={{ Display: "inline-block" }}>
                                          <SemanticDatepicker
                                            style={{ width: "50px !important" }}
                                            value={new Date(datas.EndDate)}
                                            name="EndDate"
                                            onChange={(e, data) =>
                                              handleChange(e, datas.UID, data)
                                            }
                                          /></span>
                                      ) :
                                      (
                                        <span style={{ Display: "inline-block" }}>
                                          <SemanticDatepicker
                                            style={{ width: "50px !important" }}
                                            placeholder={(datas.EndDate)}
                                            name="EndDate"
                                            onChange={(e, data) =>
                                              handleChange(e, datas.UID, data)
                                            }
                                          />
                                        </span>
                                      )
                                    }
                                  </td>

                                  <td className="borderR">
                                    <b>
                                      <span style={{ display: "inline-block" }}>
                                        Days :
                                        <input
                                          type="number"
                                          value={datas.NoOfWorkingDays}
                                          style={{ width: "60px" }}
                                          name="NoOfWorkingDays"
                                          onChange={(e) =>
                                            handleChange(e, datas.UID, null)
                                          }
                                        />
                                      </span>
                                    </b>

                                    <br />
                                    <b>
                                      <span style={{ display: "inline-block" }}>
                                        Participants :{" "}
                                        <input
                                          type="number"
                                          value={datas.NoOfParticipate}
                                          style={{ width: "60px" }}
                                          name="NoOfParticipate"
                                          onChange={(e) =>
                                            handleChange(e, datas.UID, null)
                                          }
                                        />
                                      </span>
                                    </b>
                                  </td>

                                  <td>
                                    <span>
                                      <input
                                        type="text"
                                        value={datas.InvAmt}
                                        style={{ width: "60px" }}
                                        disabled={isSales}
                                        name="InvAmt"
                                        onChange={(e) =>
                                          handleChange(e, datas.UID, null)
                                        }
                                      />
                                    </span>
                                  </td>



                                  {AddColumnForCurrecny ? (
                                    <td>
                                      <span>
                                        <input
                                          type="text"
                                          value={datas.InvAmt1}
                                          style={{ width: "60px" }}
                                          name="InvAmt1"
                                          onChange={(e) =>
                                            handleChange(e, datas.UID, null)
                                          }
                                        />
                                      </span>
                                    </td>
                                  ) : null}



                                </tr>
                              ))}
                              {/***************************************** service description row end**************************************************/}
                              {/*********************************** other extra services start *************************/}
                              {ExtraServiceSection()}
                              {/********************************** other extra services end ***************************/}
                              {callForTax()}
                              {/***************************************** service description row end**************************************************/}
                              {callForBankComm()}
                              {/***************************************** bank details row start**************************************************/}

                              {((JSON.parse(d).user_id == "138") || (JSON.parse(d).user_id == "14633") || (JSON.parse(d).user_id == "167") || (JSON.parse(d).user_id == "8948") || (JSON.parse(d).user_id == "7940") || (JSON.parse(d).user_id == "8321"))
                                ? <CallForBank BankData={BankData} AddColumnForCurrecny={AddColumnForCurrecny} />
                                : null
                              }
                              {/***************************************** bank details row end**************************************************/}
                              {
                                <tr>
                                  <td style={{ border: "none" }}></td>
                                  <td
                                    style={{ border: "none", float: "right" }}
                                  >
                                    <strong>Total</strong>
                                  </td>
                                  <td style={{ border: "none" }}></td>
                                  <td style={{ border: "none" }}></td>
                                  <td style={{ border: "none" }}></td>
                                  <td style={{ border: "none" }}>
                                    <strong>
                                      <span> {Currency} </span>
                                      <span>
                                        <input
                                          type="text"
                                          value={GrandTotal}
                                          disabled={isSales}
                                          style={{ width: "60px" }}
                                          onChange={(e) =>
                                            set_GrandTotal(e.target.value)
                                          }
                                        ></input>{" "}
                                      </span>
                                    </strong>
                                    <br />
                                    <div
                                      style={{
                                        float: "right",
                                        marginRight: "5px",
                                      }}
                                    >
                                      E. &amp; O.E
                                    </div>
                                  </td>
                                  {AddColumnForCurrecny ? (
                                    <td style={{ border: "none" }}>
                                      <strong>

                                        <span>
                                          <input
                                            type="text"
                                            value={GrandTotal1}
                                            style={{ width: "60px" }}
                                            disabled={isSales}
                                            onChange={(e) =>
                                              set_GrandTotal1(e.target.value)
                                            }

                                          ></input>
                                          <br />
                                          <div
                                            style={{
                                              float: "right",
                                              marginRight: "5px",
                                            }}
                                          >
                                            E. &amp; O.E
                                          </div>
                                        </span>
                                      </strong>
                                      <br />
                                    </td>
                                  ) : null}


                                </tr>
                              }
                            </tbody>
                          </table>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table
                            width="100%"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ border: "none" }}
                          >
                            <tbody>
                              <tr>
                                <td width="54%">
                                  <table
                                    width="100%"
                                    border="0"
                                    cellSpacing="0"
                                    cellPadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td colSpan="2">&nbsp;</td>
                                      </tr>
                                      <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                          <strong>Declaration</strong>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                          <span>
                                            Late payment processing fee of 12%
                                            P.A. compounded quarterly
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan="2">&nbsp;</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td width="46%" valign="bottom">
                                  <table
                                    width="100%"
                                    border="0"
                                    cellSpacing="0"
                                    cellPadding="0"
                                    style={{
                                      borderBottom: "0",
                                      borderRight: "0",
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td align="right">
                                          <strong>
                                            For{" "}
                                            <span>
                                              Koenig Solutions Private Ltd.
                                            </span>
                                          </strong>
                                        </td>
                                        <td align="right">&nbsp;</td>
                                      </tr>
                                      <tr>
                                        <td colSpan="2" align="right">
                                          <br />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="right" colSpan="2">
                                          <CallForSignature
                                            invoiceNumber={invoiceNo}
                                          />
                                          <br /> Authorised Signatory
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>

          <Button
            color="teal"
            content="Save"
            onClick={() => {
              checkStatusInvoice(JSON.stringify({ "InvoiceNo": invoiceNo }))
            }}
          />
        </center>
      )}
    </div>
  );
};

export default SecondStep;
