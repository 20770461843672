import React, { useEffect } from "react";
import {
  Image,
  Header,
  Popup,
  Input,
  Label,
  Icon,
  Loader,
  Button,
  Modal,
  Table,
  Container,
  Segment,
  Checkbox,
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import CustomDropDown from "../../Nishant/MainProject/Reuseble/Componant/CustomDropDown";
import {
  Line,
  PluginServiceGlobalRegistrationAndOptions,
} from "react-chartjs-2";

import Trainer from "../../reusable/Trainer";
import "./NR.css";
const axios = require("axios");

export default function NR() {
  const [loading, set_loading] = React.useState(true);
  const [data_list, set_data_list] = React.useState([]);
  const [header, set_header] = React.useState([]);

  const [pandav_loading, set_pandav_loading] = React.useState(false);
  const [sales_loading, set_sales_loading] = React.useState(false);
  const [atm_loading, set_atm_loading] = React.useState(false);
  const [asm_loading, set_asm_loading] = React.useState(false);
  const [freelancer_loading, set_freelancer_loading] = React.useState(false);
  const [cce_loading, set_cce_loading] = React.useState(false);
  const [vip_nj_loading, set_vip_nj_loading] = React.useState(false);
  const [vip_nj_panel, set_vip_nj_panel] = React.useState(false);
  const [sd, set_sd] = React.useState("");
  const [ed, set_ed] = React.useState("");
  const [cdate, set_cdate] = React.useState(null);
  const [ispaid, set_ispaid] = React.useState(false);
  //Generate Graph
  const [chartData, setChartData] = React.useState([]);
  const [freelancerData, setFreelancerData] = React.useState([]);
  const [ManagerChartData, setManagerChartData] = React.useState([]);
  const [CceChartData, setCceChartData] = React.useState([]);
  const [showFreelancerGraph, setShowFreelancerGraph] = React.useState(false);
  const [showManagerGraph, setShowManagerGraph] = React.useState(false);
  const [showCceGraph, setShowCceGraph] = React.useState(false);
  const [showGraph, setShowGraph] = React.useState(false);
  const [CellManagerName, setCellManagerName] = React.useState("");
  const [CellCceName, setCellCceName] = React.useState("");
  const [CellCceManagerName, setCellCceManagerName] = React.useState("");
  const [modalOpen, setModalOpen] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [modal_header, set_modal_header] = React.useState("");

  const [non_esi_gsi, set_non_esi_gsi] = React.useState(true);
  const [esi_gsi, set_esi_gsi] = React.useState(true);
  const [intrest_debit, set_intrest_debit] = React.useState(false);
  const [internal_transfer, set_internal_transfer] = React.useState(true);
  const [sc_with_cancel, set_sc_with_cancel] = React.useState(true);
  const [miscellaneous, set_miscellaneous] = React.useState(true);
  const [mo_bonus, set_mo_bonus] = React.useState(false);
  const [busy_weak, set_busy_weak] = React.useState(false);
  const [trainer_cost, set_trainer_cost] = React.useState(true);

  // Added  is_exclude_commision_sc filter  which is applicable for cce_nr function on 01-05-2023 by sricharan

  const [is_exclude_commision_sc, set_commision_scs] = React.useState(true);

  //const [with_cost_for_sc_cancelled, set_with_cost_for_sc_cancelled] = React.useState(false)
  const [nudge_deduct, set_nudge_deduct] = React.useState(false);
  const [bad_dept, set_bad_dept] = React.useState(false);
  const [new_formula, set_new_formula] = React.useState(false);
  const [directNrOfAsm, set_directNrOfAsm] = React.useState(false);
  const [
    marketing_salery_certification,
    set_marketing_salery_certification,
  ] = React.useState(true);

  const [month, set_month] = React.useState(1);

  const [header_pandav, set_header_pandav] = React.useState([]);
  const [pandav_data_list, set_pandav_data_list] = React.useState([]);

  const [header_sales_manager, set_header_sales_manager] = React.useState([]);
  const [sales_manager_data_list, set_sales_manager_data_list] = React.useState(
    []
  );
  const [
    sales_manager_data_list_last_year,
    set_sales_manager_data_list_last_year,
  ] = React.useState([]);

  const [header_freelancer, set_header_freelancer] = React.useState([]);
  const [freelancer_data_list, set_freelancer_data_list] = React.useState([]);
  const [
    freelancer_data_list_last_year,
    set_freelancer_data_list_last_year,
  ] = React.useState([]);

  const [header_cce, set_header_cce] = React.useState([]);
  const [cce_data_list, set_cce_data_list] = React.useState([]);
  const [cce_data_list_last_year, set_cce_data_list_last_year] = React.useState(
    []
  );

  const [header_vip_nj, set_header_vip_nj] = React.useState([]);
  const [vip_nj_data_list, set_vip_nj_data_list] = React.useState([]);

  const [header_atm, set_header_atm] = React.useState([]);
  const [atm_data_list, set_atm_data_list] = React.useState([]);

  const [header_asm, set_header_asm] = React.useState([]);
  const [asm_data_list, set_asm_data_list] = React.useState([]);
  const [asm_data_list_last_year, set_asm_data_list_last_year] = React.useState(
    []
  );

  const [cell_data, set_cell_data] = React.useState([]);
  const [loading1, set_loading1] = React.useState(false);

  const [firstOpen, set_FirstOpen] = React.useState(false);
  const [busy_date, set_date] = React.useState(false);
  const [trainer, set_trainer] = React.useState("");
  const [busy_list, set_busy_list] = React.useState([]);
  const [ContinentId, setContinentId] = React.useState("");
  const [CountryId, setCountryId] = React.useState("");
  const [CurrencyCode, setCurrencyCode] = React.useState(1);
  const [DisableCountry, setDisableCountry] = React.useState(false);
  const [DisableContinent, setDisableContinent] = React.useState(false);
  //NRD Last Month Same Year
  const [
    isDisplaySameMonthLastYear,
    set_isDisplaySameMonthLastYear,
  ] = React.useState(true);
  let [isSmly, set_isSmly] = React.useState(true);
  const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

  const toggleGraph = () => {
    setShowGraph(true);
  };

  const toggleFreelancerGraph = () => {
    setShowFreelancerGraph(true);
  };

  const toggleManagerGraph = () => {
    setShowManagerGraph(true);
  };

  const toggleCceGraph = () => {
    setShowCceGraph(true);
  };

  const generateChartData = () => {
    if (!chartData.length) return [];
    const values = Object.values(chartData[0]).slice(1);
    const filteredHeader = header_sales_manager.filter(
      (item) => item !== "Manager"
    );
    return [filteredHeader, [...Object.values(values)]];
  };

  const generateFreelancerChartData = () => {
    if (!freelancerData.length) return [];
    const values = Object.values(freelancerData[0]).slice(1);
    const filteredHeader = header_freelancer.filter(
      (item) => item !== "Manager"
    );
    return [filteredHeader, [...Object.values(values)]];
  };

  const generateManagerChartData = () => {
    if (!ManagerChartData.length) return [];

    const managerRecord = ManagerChartData.filter(
      (manager) => manager.Manager === CellManagerName
    );
    const values = Object.values(managerRecord[0]).slice(1);
    const filteredHeader = header_asm.filter((item) => item !== "Manager");
    return [filteredHeader, [...Object.values(values)]];
  };

  const generateCceData = () => {
    if (!CceChartData.length) return [];

    const managerRecord = CceChartData.find(
      (manager) =>
        manager.Manager === CellCceManagerName && manager.CCE == CellCceName
    );

    const numericValues = [...Object.values(managerRecord)]
      .map((item, index) => {
        // Create a temporary element to parse the HTML
        const tempElement = document.createElement("div");
        tempElement.innerHTML = item;

        // Extract the numeric value
        const numericValue = tempElement.textContent.replace(/[^0-9-]/g, "");

        // Convert the numeric value to an integer
        const integerValue = parseInt(numericValue, 10);

        // Check if the conversion resulted in a valid number
        const isValidNumber = !isNaN(integerValue);

        // Return the numeric value for elements other than the first one
        return isValidNumber ? integerValue : null;
        // Return original content for the first element
      })
      .filter((value) => value !== null);

    const filteredHeader = header_cce.filter(
      (item) => item !== "CCE" && item !== "Manager"
    );

    return [filteredHeader, [...Object.values(numericValues)]];
  };

  const LineGraph = ({ data }) => {
    const closeModal = () => {
      setModalOpen(false);
      setShowFreelancerGraph(false);
      setShowManagerGraph(false);
      setShowCceGraph(false);
      setShowGraph(false);
    };

    const chartData = {
      labels: data[0],
      datasets: [
        {
          label: "Value",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
          data: data[1],
        },
      ],
    };

    const chartOptions = {
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            type: "category",
            labels: data[0],
          },
        ],
        yAxes: [
          {
            ticks: {
              maxTicksLimit: data[1].length,
              callback: function (value) {
                return parseFloat(value).toLocaleString("en-IN", {
                  style: "decimal",
                  useGrouping: true,
                });
              },
            },
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: true,
      legend: {
        display: true,
        position: "top",
      },
      tooltips: {
        enabled: true,
        mode: "single",
        callbacks: {
          label: function (tooltipItem, data) {
            return parseFloat(tooltipItem.yLabel).toLocaleString("en-IN", {
              style: "decimal",
              useGrouping: true,
            });
          },
        },
      },
    };

    // Adjust the size of the container
    const containerStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "400px", // Set the desired height
      width: "800px", // Set the desired width
      marginLeft: "100px",
    };

    return (
      <div>
        <Modal
          open={modalOpen}
          onClose={closeModal}
          closeOnDimmerClick={false}
          closeOnEscape={false}
        >
          <Modal.Header>Graph Modal</Modal.Header>
          <Modal.Content>
            <div style={containerStyle}>
              <Line data={chartData} options={chartOptions} />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={closeModal}>Close</Button>
            {/* You can add additional buttons or controls here */}
          </Modal.Actions>
        </Modal>
      </div>
    );
  };

  const GetContinentId = (text, value) => setContinentId(value);
  const GetCountryId = (text, value) => setCountryId(value);
  const GetCurrencyCode = (text, value) => setCurrencyCode(value);

  const addMonths = (input, months) => {
    const date = new Date(input);
    date.setDate(1);
    date.setMonth(date.getMonth() + months - 1);
    date.setDate(
      Math.min(
        input.getDate(),
        getDaysInMonth(date.getFullYear(), date.getMonth() + 1)
      )
    );
    return date;
  };

  useEffect(() => {
    // pandav_nr(month);
    sales_manager_nr_last_year_same_month(month);
    sales_manager_nr(month);
    // atm_nr(month);
    freelancer_nr_last_year_same_month(month);
    freelancer_nr(month);
    cce_nr_last_year_same_month(month);
    cce_nr(month);
    asm_nr_same_month_last_year(month);
    asm_nr(month);
    set_cdate(getCurrentDate("-"));
    vip_nj(cdate);
  }, [month]);

  function mark_busy(flag) {
    // alert(flag)
    // alert(busy_date.toLocaleDateString('en-US'))
    // alert(trainer)
    let d = localStorage.getItem("emp_data");

    let modified_by = "yusuf";
    axios
      .get(
        "yusuf/mark_weak_busy?t=" +
        JSON.parse(d).UserName +
        "&d=" +
        busy_date.toLocaleDateString("en-US") +
        "&f=" +
        flag +
        "&m=" +
        modified_by +
        ""
      )
      .then(function (response) {
        alert(response.data[0].status);
        get_weak_busy();
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function get_weak_busy() {
    axios.get("yusuf/get_weak_busy").then(function (response) {
      set_busy_list(response.data);
    });
  }

  function atm_nr(m, filters = "0,1,2,3,4,7,6,8,9,16,17,18,19,22") {
    set_atm_loading(true);
    var d = new Date();
    d = addMonths(d, m);
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();

    let date = curr_year + "-" + curr_month + "-01";
    axios
      .get(
        "yusuf/atm_nr?st_date=2020-04-01&ed_date=" +
        date +
        "&filters=" +
        filters +
        "&type=" +
        (new_formula ? "new formula" : "old formula") +
        "&ispaid=" +
        ispaid +
        ""
      )
      .then(function (response) {
        set_header_atm(Object.keys(response.data[0]));
        set_atm_loading(false);
        set_atm_data_list(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function asm_nr(m, filters = "0,1,2,3,4,7,6,16,17,18,19,22") {
    set_asm_loading(true);

    var d = new Date();
    d = addMonths(d, m);
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();

    let date = curr_year + "-" + curr_month + "-01";
    axios
      .get(
        "yusuf/asm_nr?st_date=2020-04-01&ed_date=" +
        date +
        "&filters=" +
        filters +
        "&type=" +
        (new_formula ? "new formula" : "old formula") +
        "&ispaid=" +
        ispaid +
        "&directNrOfAsm=" +
        directNrOfAsm +
        "&countryId=" +
        CountryId +
        "&continentId=" +
        ContinentId +
        "&currencyCode=" +
        CurrencyCode +
        "&is_exclude_commision_sc=" +
        (is_exclude_commision_sc ? 1 : 0) +
        ""
      )
      .then(function (response) {
        set_header_asm(Object.keys(response.data[0]));
        set_asm_loading(false);
        set_asm_data_list(response.data);
        setManagerChartData(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function asm_nr_same_month_last_year(
    m,
    filters = "0,1,2,3,4,7,6,16,17,18,19,22"
  ) {
    var d = new Date();
    d = addMonths(d, m);
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear() - 1;

    let date = curr_year + "-" + curr_month + "-01";
    axios
      .get(
        "yusuf/asm_nr?st_date=2020-04-01&ed_date=" +
        date +
        "&filters=" +
        filters +
        "&type=" +
        (new_formula ? "new formula" : "old formula") +
        "&ispaid=" +
        ispaid +
        "&directNrOfAsm=" +
        directNrOfAsm +
        "&countryId=" +
        CountryId +
        "&continentId=" +
        ContinentId +
        "&currencyCode=" +
        CurrencyCode +
        "&is_exclude_commision_sc=" +
        (is_exclude_commision_sc ? 1 : 0) +
        ""
      )
      .then(function (response) {
        set_asm_data_list_last_year(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function cce_nr(m, filters = "0,1,2,3,4,7,6,16,17,18,19,22") {
    var d = new Date();
    set_cce_loading(true);
    d = addMonths(d, m);
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();

    // alert(CountryId == "" ? "0" : CountryId);
    // alert(ContinentId == "" ? "0" : ContinentId);
    let date = curr_year + "-" + curr_month + "-01";
    axios
      .get(
        "yusuf/cce_nr?st_date=2020-04-01&ed_date=" +
        date +
        "&filters=" +
        filters +
        "&type=" +
        (new_formula ? "new formula" : "old formula") +
        "&ispaid=" +
        ispaid +
        "&countryId=" +
        CountryId +
        "&continentId=" +
        ContinentId +
        "&currencyCode=" +
        CurrencyCode +
        // Added  is_exclude_commision_sc filter  which is applicable for cce_nr function on 01-05-2023 by sricharan
        "&isSmly=" +
        (isSmly ? 1 : 0) +
        "&is_exclude_commision_sc=" +
        (is_exclude_commision_sc ? 1 : 0) +
        ""
      )
      .then(function (response) {
        set_header_cce(Object.keys(response.data[0]));
        set_cce_loading(false);
        let cce_data = response.data;
        let cceDataList = [...cce_data];
        const managerNamesSet = new Set(
          cceDataList.map((item) => item.Manager)
        );
        const distinctManagerNames = Array.from(managerNamesSet);
        let arrangedDataList = [];
        let currentManager = null;
        let correspondingManagerRecordPushed = false;

        cceDataList.forEach((record) => {
          let managerName = record["Manager"];
          let index = record["CCE"].indexOf("-");
          let cceSubstring = record["CCE"].substring(0, index).trim();

          if (managerName !== currentManager) {
            currentManager = managerName;
            if (!distinctManagerNames.includes(cceSubstring)) {
              arrangedDataList.push(record);
            }
            correspondingManagerRecordPushed = false; // Reset the flag for new manager
          } else {
            if (!correspondingManagerRecordPushed) {
              let correspondingManagerRecord = cceDataList.find((item) => {
                let hyphenIndex = item["CCE"].indexOf("-");
                if (hyphenIndex !== -1) {
                  let cceSubstring = item["CCE"]
                    .substring(0, hyphenIndex)
                    .trim();
                  return cceSubstring === managerName;
                }
                return false;
              });
              if (correspondingManagerRecord) {
                arrangedDataList.push(correspondingManagerRecord);
                correspondingManagerRecordPushed = true; // Set the flag after pushing the record
              }
            }
            if (!distinctManagerNames.includes(cceSubstring)) {
              arrangedDataList.push(record);
            }
          }
        });
        set_cce_data_list(arrangedDataList);
        setCceChartData(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function cce_nr_last_year_same_month(
    m,
    filters = "0,1,2,3,4,7,6,16,17,18,19,22"
  ) {
    var d = new Date();
    d = addMonths(d, m);
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear() - 1;

    // alert(CountryId == "" ? "0" : CountryId);
    // alert(ContinentId == "" ? "0" : ContinentId);
    let date = curr_year + "-" + curr_month + "-01";
    axios
      .get(
        "yusuf/cce_nr?st_date=2020-04-01&ed_date=" +
        date +
        "&filters=" +
        filters +
        "&type=" +
        (new_formula ? "new formula" : "old formula") +
        "&ispaid=" +
        ispaid +
        "&countryId=" +
        CountryId +
        "&continentId=" +
        ContinentId +
        "&currencyCode=" +
        CurrencyCode +
        // Added  is_exclude_commision_sc filter  which is applicable for cce_nr function on 01-05-2023 by sricharan
        "&isSmly=" +
        1 +
        "&is_exclude_commision_sc=" +
        (is_exclude_commision_sc ? 1 : 0) +
        ""
      )
      .then(function (response) {
        let cce_data = response.data;
        let cceDataList = [...cce_data];
        const managerNamesSet = new Set(
          cceDataList.map((item) => item.Manager)
        );
        const distinctManagerNames = Array.from(managerNamesSet);
        let arrangedDataList = [];
        let currentManager = null;
        let correspondingManagerRecordPushed = false;

        cceDataList.forEach((record) => {
          let managerName = record["Manager"];
          let index = record["CCE"].indexOf("-");
          let cceSubstring = record["CCE"].substring(0, index).trim();
          if (managerName !== currentManager) {
            currentManager = managerName;
            if (!distinctManagerNames.includes(cceSubstring)) {
              arrangedDataList.push(record);
            }
            correspondingManagerRecordPushed = false; // Reset the flag for new manager
          } else {
            if (!correspondingManagerRecordPushed) {
              let correspondingManagerRecord = cceDataList.find((item) => {
                let hyphenIndex = item["CCE"].indexOf("-");
                if (hyphenIndex !== -1) {
                  let cceSubstring = item["CCE"]
                    .substring(0, hyphenIndex)
                    .trim();
                  return cceSubstring === managerName;
                }
                return false;
              });
              if (correspondingManagerRecord) {
                arrangedDataList.push(correspondingManagerRecord);
                correspondingManagerRecordPushed = true; // Set the flag after pushing the record
              }
            }
            if (!distinctManagerNames.includes(cceSubstring)) {
              arrangedDataList.push(record);
            }
          }
        });
        set_cce_data_list_last_year(arrangedDataList);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  async function vip_nj() {
    set_cdate(getCurrentDate("-"));
    set_vip_nj_data_list([]);
    set_vip_nj_loading(true);

    let jss_start = new Date(cdate);
    let ssd_ =
      jss_start.getMonth() +
      1 +
      "/" +
      jss_start.getDate() +
      "/" +
      jss_start.getFullYear();

    if (cdate == null) {
      ssd_ = getCurrentDate("-");
    }

    let { data } = await axios.get("yusuf/vipnj?cdate=" + ssd_ + "");
    if (data.length > 0) {
      set_header_vip_nj(Object.keys(data[0]));
      set_vip_nj_data_list(data);
    } else {
      alert("NO DATA");
    }
    set_loading(false);
  }

  function freelancer_nr(m, filters = "0,1,2,3,4,7,6,16,17,18,19,22") {
    set_freelancer_loading(true);
    var d = new Date();
    d = addMonths(d, m);
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();

    let date = curr_year + "-" + curr_month + "-01";
    axios
      .get(
        "yusuf/freelancer_nr?st_date=2020-04-01&ed_date=" +
        date +
        "&filters=" +
        filters +
        "&type=" +
        (new_formula ? "new formula" : "old formula") +
        "&currencyCode=" +
        CurrencyCode +
        "&ispaid=" +
        ispaid +
        ""
      )
      .then(function (response) {
        set_header_freelancer(Object.keys(response.data[0]));
        set_freelancer_loading(false);
        set_freelancer_data_list(response.data);
        setFreelancerData(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function freelancer_nr_last_year_same_month(
    m,
    filters = "0,1,2,3,4,7,6,16,17,18,19,22"
  ) {
    var d = new Date();
    d = addMonths(d, m);
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear() - 1;

    let date = curr_year + "-" + curr_month + "-01";
    axios
      .get(
        "yusuf/freelancer_nr?st_date=2020-04-01&ed_date=" +
        date +
        "&filters=" +
        filters +
        "&type=" +
        (new_formula ? "new formula" : "old formula") +
        "&currencyCode=" +
        CurrencyCode +
        "&ispaid=" +
        ispaid +
        ""
      )
      .then(function (response) {
        set_freelancer_data_list_last_year(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function sales_manager_nr(m, filters = "0,1,2,3,4,7,6,16,17,18,19,22") {
    set_sales_loading(true);

    var d = new Date();
    d = addMonths(d, m);
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();

    let date = curr_year + "-" + curr_month + "-01";

    axios
      .get(
        "yusuf/sales_manager_nr?st_date=2020-04-01&ed_date=" +
        date +
        "&filters=" +
        filters +
        "&type=" +
        (new_formula ? "new formula" : "old formula") +
        "&currencyCode=" +
        CurrencyCode +
        "&ispaid=" +
        ispaid +
        ""
      )
      .then(function (response) {
        set_header_sales_manager(Object.keys(response.data[0]));
        set_sales_loading(false);
        set_sales_manager_data_list(response.data);
        setChartData(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function sales_manager_nr_last_year_same_month(
    m,
    filters = "0,1,2,3,4,7,6,16,17,18,19,22"
  ) {
    var d = new Date();
    d = addMonths(d, m);
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear() - 1;

    let date = curr_year + "-" + curr_month + "-01";

    axios
      .get(
        "yusuf/sales_manager_nr?st_date=2020-04-01&ed_date=" +
        date +
        "&filters=" +
        filters +
        "&type=" +
        (new_formula ? "new formula" : "old formula") +
        "&currencyCode=" +
        CurrencyCode +
        "&ispaid=" +
        ispaid +
        ""
      )
      .then(function (response) {
        set_sales_manager_data_list_last_year(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function pandav_nr(
    m,
    filters = "0,1,2,3,4,7,6,8,9,16,17,18,19,22",
    from = "",
    to = ""
  ) {
    set_pandav_loading(true);
    var d = new Date();
    d = addMonths(d, m);
    var curr_date = d.getDate();
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();

    let date = curr_year + "-" + curr_month + "-01";

    //alert(date)
    axios
      .get(
        "yusuf/pandav_nr?st_date=2020-02-01&ed_date=" +
        date +
        "&filters=" +
        filters +
        "&type=" +
        (new_formula ? "new formula" : "old formula") +
        "&from=" +
        from +
        "&to=" +
        to +
        "&ispaid=" +
        ispaid +
        ""
      )
      .then(function (response) {
        set_header_pandav(Object.keys(response.data[0]));
        set_pandav_loading(false);
        set_pandav_data_list(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function filter() { }

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    // return `${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}${separator}${year}`
    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${date < 10 ? `0${date}` : `${date}`}`;
  }

  async function cell_onclick(u, m, t) {
    set_cell_data([]);

    set_modal_header(u + " : " + m);
    setOpen(true);
    set_loading1(true);

    let checks = "";
    if (non_esi_gsi && !new_formula) checks = "0";
    if (non_esi_gsi && new_formula) checks = "10";
    if (esi_gsi && !new_formula) checks += ",1,2,3,4";
    if (esi_gsi && new_formula) checks += ",11,12,13,14";
    if (intrest_debit) checks += ",5";
    if (internal_transfer) checks += ",6";
    if (sc_with_cancel) checks += ",7";
    if (bad_dept) checks += ",8";
    if (nudge_deduct) checks += ",9";
    if (marketing_salery_certification) checks += ",16,17,18";
    if (miscellaneous) checks += ",19";
    if (mo_bonus) checks += ",20";
    if (busy_weak) checks += ",21";
    if (trainer_cost) checks += ",22";

    if (checks.charAt(0) == ",") {
      checks = checks.substring(1);
    }

    let js_start = new Date(sd);
    let js_end = new Date(ed);
    let sd_ =
      js_start.getMonth() +
      1 +
      "/" +
      js_start.getDate() +
      "/" +
      js_start.getFullYear();
    let ed_ =
      js_end.getMonth() +
      1 +
      "/" +
      js_end.getDate() +
      "/" +
      js_end.getFullYear();
    if (sd == "") {
      sd_ = "07/01/2020";
    }
    if (ed == "") {
      ed_ = "11/10/2020";
    }

    //nr_cell_click

    axios
      .get(
        "yusuf/nr_cell_click?u=" +
        u +
        "&m=" +
        m +
        "&t=" +
        t +
        "&filters=" +
        checks +
        "&new_formula=" +
        (new_formula ? "new formula" : "old formula") +
        "&from=" +
        sd_ +
        "&to=" +
        ed_ +
        "&ispaid=" +
        ispaid +
        "&countryId=" +
        CountryId +
        "&directNrOfAsm=" +
        directNrOfAsm +
        "&continentId=" +
        ContinentId +
        "&currencyCode=" +
        CurrencyCode +
        "&is_exclude_commision_sc=" +
        (is_exclude_commision_sc ? 1 : 0) +
        ""
      )
      .then(function (response) {
        //alert(JSON.stringify(response.data))
        set_cell_data(response.data);
        set_loading1(false);
      })
      .catch(function (error) {
        alert(error);
      });
  }
  return (
    <div
      style={{
        width: "98vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ffffff",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <Modal
            open={vip_nj_panel}
            onClose={() => set_vip_nj_panel(false)}
            onOpen={() => set_vip_nj_panel(true)}
            style={{
              marginTop: "15vh",
              marginLeft: "10%",
              width: "80%",
              height: "66vh",
            }}
          >
            <Modal.Header style={{ height: "9vh" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h4>VIP NJ</h4>
                <div style={{ width: "86%" }}>
                  <center>
                    <SemanticDatepicker
                      placeholder={cdate}
                      onChange={(e, v) => {
                        //alert(JSON.stringify(v))
                        set_cdate(v.value);
                      }}
                    />
                    <Button
                      className="btnView"
                      onClick={() => {
                        vip_nj();
                      }}
                      primary
                    >
                      View
                    </Button>
                  </center>
                </div>
                <div style={{ position: "absolute", right: 10 }}>
                  <Button onClick={() => set_vip_nj_panel(false)} primary>
                    Close <Icon style={{ marginLeft: 10 }} name="close" />
                  </Button>
                </div>
              </div>
            </Modal.Header>

            <Modal.Content scrolling>
              <Modal.Description>
                <Table className="njvip" celled fixed singleLine>
                  <Table.Header>
                    <Table.Row>
                      {/* <Table.HeaderCell>Name</Table.HeaderCell> */}
                      <Table.HeaderCell>S.No</Table.HeaderCell>
                      <Table.HeaderCell>Rank</Table.HeaderCell>
                      <Table.HeaderCell>Categories</Table.HeaderCell>
                      <Table.HeaderCell>Domestic NR</Table.HeaderCell>
                      <Table.HeaderCell>Domestic CCE</Table.HeaderCell>
                      <Table.HeaderCell>International NR</Table.HeaderCell>
                      <Table.HeaderCell>International CCE</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {vip_nj_data_list.map((d) => {
                      return (
                        <Table.Row>
                          {/* <Table.Cell>{d.Name}</Table.Cell> */}
                          <Table.Cell>{d.SNo}</Table.Cell>
                          <Table.Cell>{d.Rank}</Table.Cell>
                          <Table.Cell>{d.Categories}</Table.Cell>
                          <Table.Cell>{d.DomesticNR}</Table.Cell>
                          <Table.Cell>{d.DomesticCCE}</Table.Cell>
                          <Table.Cell>{d.InternationalNR}</Table.Cell>
                          <Table.Cell>{d.InternationalCCE}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </div>
      </div>

      <Modal
        closeIcon
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Modal.Header>{modal_header} </Modal.Header>

        <Modal.Content scrolling>
          <Modal.Description>
            <div style={{ display: loading1 ? "block" : "none" }}>
              <Loader
                size="big"
                style={{ backgroundColor: "#333" }}
                active
              ></Loader>
            </div>

            <Table
              id="cell_table"
              celled
              fixed
              singleLine
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Assignment ID</Table.HeaderCell>
                  <Table.HeaderCell>Revenue</Table.HeaderCell>
                  <Table.HeaderCell>Month</Table.HeaderCell>
                  <Table.HeaderCell>Reason</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {cell_data.map((d, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>
                        <b>{d.name}</b>{" "}
                      </Table.Cell>
                      <Table.Cell>
                        <b>{d.a_id}</b>{" "}
                      </Table.Cell>
                      <Table.Cell>
                        <b>
                          {" "}
                          {d.nr == null
                            ? 0
                            : parseInt(d.nr).toLocaleString("en-IN")}
                        </b>
                      </Table.Cell>
                      <Table.Cell>
                        <b>{d.month}</b>{" "}
                      </Table.Cell>
                      <Table.Cell>
                        <b>{d.nrtype}</b>{" "}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Modal.Description>
        </Modal.Content>
      </Modal>
      {/* HEADER */}
      <div
        style={{
          display: "flex",
          padding: 20,
          height: "8vh",
          backgroundColor: "#fafafb",
          width: "100vw",
          WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
          MozBoxShadow: "0 8px 6px -6px #d5d4d6",
          boxShadow: "0 8px 6px -6px #d5d4d6",
        }}
      >
        <span
          style={{
            fontSize: 16,
            fontWeight: "bold",
            fontFamily: "sans-serif",
            marginLeft: 15,
            alignSelf: "center",
          }}
        >
          NR Report
        </span>
        <div
          style={{
            display: "inline-block",
            textAlign: "center",
            marginLeft: 100,
          }}
        >
          <div style={{ display: "inline-block", marginRight: 20 }}>
            <span
              style={{
                display: "inline-block",
                width: 20,
                height: 10,
                backgroundColor: "red",
                marginRight: 5,
              }}
            ></span>
            <span>Total NR &lt; SMLY Or -Ve</span>
          </div>
          <div style={{ display: "inline-block", marginRight: 20 }}>
            <span
              style={{
                display: "inline-block",
                width: 20,
                height: 10,
                backgroundColor: "goldenrod",
                marginRight: 5,
              }}
            ></span>
            <span>Growth &gt; 20%</span>
          </div>
          <div style={{ display: "inline-block" }}>
            <span
              style={{
                display: "inline-block",
                width: 20,
                height: 10,
                backgroundColor: "black",
                marginRight: 5,
              }}
            ></span>
            <span>Growth between 0 - 20%</span>
          </div>
        </div>
        {/* <div className="inner" style={{ display: 'flex', flex: 4, alignSelf: 'center' }}>
                    <div style={{ margin: 10 }}>
                                <Label
                            style={{ margin: 10, cursor: 'pointer', fontWeight: 'bolder', }}
                            onClick={() => {
                                //logout();
                                set_month(month + 6)
                            }}
                        >
                            {" "}
                            <Icon color="" size="large" name="arrow alternate circle left" />{"\u00A0"} Next 6 month  {" "}
                        </Label>
                    </div>

                    <div style={{ margin: 10 }}>
                                 <Label
                            style={{ margin: 10, cursor: 'pointer', fontWeight: 'bolder' }}
                            onClick={() => {
                                // logout();
                                set_month(month - 6)
                            }}
                        >
                            {" "}
                            Last 6 month {"\u00A0"} <Icon size="large" color="" name="arrow alternate circle right" />{" "}
                        </Label>
                    </div>
                </div> */}

        <div style={{ display: "none", flex: 4, alignSelf: "center" }}>
          <Modal
            onClose={() => {
              set_FirstOpen(false);
            }}
            open={firstOpen}
          >
            <Modal.Header>Mark Busy / Week</Modal.Header>
            <Modal.Content image>
              {/* <div style={{ margin: 2 }}>
                                <div style={{ width: 200 }}>
                                    <Trainer passChildData={set_trainer} />
                                </div>


                            </div> */}
              <div style={{ margin: 2 }}>
                <SemanticDatepicker
                  placeholder="Selecet Date"
                  onChange={(e, v) => {
                    //alert(JSON.stringify(v))
                    set_date(v.value);
                  }}
                />
              </div>

              <div style={{ margin: 2 }}>
                <Button
                  onClick={() => {
                    mark_busy("busy");
                  }}
                  primary
                >
                  Busy
                </Button>
                <Button
                  onClick={() => {
                    mark_busy("weak");
                  }}
                  primary
                >
                  Weak
                </Button>
              </div>
            </Modal.Content>
            <div style={{ margin: 50, overflow: "scroll" }}>
              <Table celled fixed singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Mark By</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {busy_list.map((row, ii) => (
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {row.trainer_name}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {row.date}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {row.flag}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                ))}
              </Table>
            </div>
          </Modal>

          <Label
            style={{ margin: 10, cursor: "pointer", fontWeight: "bolder" }}
            onClick={() => {
              set_FirstOpen(true);
              get_weak_busy();
              //window.location.href = 'https://rms.koenig-solutions.com/NRDelivery/NewNRDeliveryReport.aspx?ui=old'
            }}
          >
            {" "}
            Mark Busy / Weak
          </Label>
        </div>

        {/* <div
          className="vipnj"
          style={{
            display: "flex",
            flex: 4,
            alignSelf: "center",
            position: "absolute",
            right: 120,
            width: "10%",
          }}
        >
          <Button
            style={{
              margin: 10,
              fontSize: "100%",
              padding: "5% 8%",
              cursor: "pointer",
              fontWeight: "bolder",
            }}
            onClick={() => {
              set_vip_nj_panel(true);
              vip_nj(cdate);
            }}
          >
            <Icon
              size="large"
              color="teal"
              name="enter alternate circle left"
            />{" "}
            <span>VIP NJ</span>
          </Button>
        </div> */}

        {/* <div
          style={{
            display: "flex",
            flex: 4,
            alignSelf: "center",
            position: "absolute",
            right: 10,
          }}
        >
          <Label
            style={{ margin: 10, cursor: "pointer", fontWeight: "bolder" }}
            onClick={() => {
              window.location.href =
                "https://rms.koenig-solutions.com/NRDelivery/NewNRDeliveryReport.aspx?ui=old";
            }}
          >
            {" "}
            <Icon
              size="large"
              color="teal"
              name="arrow alternate circle left"
            />{" "}
            Old Version
          </Label>
        </div> */}
      </div>

      {/* FITER SECTION */}

      {/* <div style={{ display: "inline-flex", flexDirection: "column", border: "1px solid black", padding: "10px" }}> */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          paddingLeft: 43,
          paddingTop: 20,
        }}
      >
        <div className="filtering" style={{ margin: 2, flexBasis: "20%" }}>
          <Checkbox
            onChange={() => {
              set_non_esi_gsi(!non_esi_gsi);
            }}
            style={{ marginBottom: -4 }}
            defaultChecked
          />
          {/* <br></br> */}
          &nbsp;&nbsp;
          <span>NON ESI/GSI/ T4H/MATD</span>
        </div>
        <div className="filtering" style={{ margin: 2, flexBasis: "20%" }}>
          <Checkbox
            onChange={() => {
              set_esi_gsi(!esi_gsi);
            }}
            style={{ marginBottom: -4 }}
            defaultChecked
          />
          {/* <br></br> */}
          &nbsp;&nbsp;
          <span>ESI/GSI/ T4H/MATD</span>
        </div>

        <div className="filtering" style={{ margin: 2, flexBasis: "20%" }}>
          <Checkbox
            onChange={() => {
              set_internal_transfer(!internal_transfer);
            }}
            style={{ marginBottom: -4 }}
            defaultChecked
          />
          {/* <br></br> */}
          &nbsp;&nbsp;
          <span>Internal Distribution</span>
        </div>

        <div className="filtering" style={{ margin: 2, flexBasis: "20%" }}>
          <Checkbox
            onChange={() => {
              set_sc_with_cancel(!sc_with_cancel);
            }}
            style={{ marginBottom: -4 }}
            defaultChecked
          />
          {/* <br></br> */}
          &nbsp;&nbsp;
          <span>With costs for SC cancelled</span>
        </div>

        <div className="filtering" style={{ margin: 2, flexBasis: "20%" }}>
          <Checkbox
            onChange={() => {
              set_bad_dept(!bad_dept);
            }}
            style={{ marginBottom: -4 }}
          />
          {/* <br></br> */}
          &nbsp;&nbsp;
          <span>Bad Debt</span>
        </div>
        <div className="filtering" style={{ margin: 2, flexBasis: "20%" }}>
          <Checkbox
            onChange={() => {
              set_nudge_deduct(!nudge_deduct);
            }}
            style={{ marginBottom: -4 }}
          />
          {/* <br></br> */}
          &nbsp;&nbsp;
          <span>Nudge Deductions</span>
        </div>

        <div className="filtering" style={{ margin: 2, flexBasis: "20%" }}>
          <Checkbox
            onChange={() => {
              set_miscellaneous(!miscellaneous);
            }}
            style={{ marginBottom: -4 }}
            defaultChecked
          />
          {/* <br></br> */}
          &nbsp;&nbsp;
          <span>Miscellaneous</span>
        </div>

        <div className="filtering" style={{ margin: 2, flexBasis: "20%" }}>
          <Checkbox
            onChange={() => {
              set_marketing_salery_certification(
                !marketing_salery_certification
              );
            }}
            style={{ marginBottom: -4 }}
            defaultChecked
          />
          {/* <br></br> */}
          &nbsp;&nbsp;
          <span>Exclude Marketing/Salary/Certification </span>
        </div>

        <div className="filtering" style={{ margin: 2, flexBasis: "20%" }}>
          <Checkbox
            onChange={() => {
              set_trainer_cost(!trainer_cost);
            }}
            style={{ marginBottom: -4 }}
            defaultChecked
          />
          {/* <br></br> */}
          &nbsp;&nbsp;
          <span>Trainer Cost </span>
        </div>

        {/* Added  is_exclude_commision_sc filter  on 1st may 2023 by sricharan */}
        <div className="filtering" style={{ margin: 2, flexBasis: "20%" }}>
          <Checkbox
            onChange={() => {
              set_commision_scs(!is_exclude_commision_sc);
            }}
            style={{ marginBottom: -4 }}
            defaultChecked
          />
          {/* <br></br> */}
          &nbsp;&nbsp;
          <span>Exclude Client Payout SCs</span>
        </div>

        <div className="filtering" style={{ margin: 2, flexBasis: "20%" }}>
          <Checkbox
            onChange={() => {
              set_directNrOfAsm(!directNrOfAsm);
            }}
            style={{ marginBottom: -4 }}
          />
          {/* <br></br> */}
          &nbsp;&nbsp;
          <span>Direct NR of ASMs</span>
        </div>

        <div className="filtering" style={{ margin: 2, flexBasis: "20%" }}>
          <Checkbox
            onChange={() => {
              set_isDisplaySameMonthLastYear(!isDisplaySameMonthLastYear);
              set_isSmly(!isSmly);
              isSmly = !isSmly;
              cce_nr(month);
            }}
            defaultChecked
            style={{ marginBottom: -4 }}
          />
          {/* <br></br> */}
          &nbsp;&nbsp;
          <span>Display Same Month Last Year</span>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          paddingLeft: 43,
          paddingTop: 20,
        }}
      >
        <div className="filtering" style={{ margin: 2, width: "20%" }}>
          <CustomDropDown
            passChildData={GetCountryId}
            disabled={DisableCountry}
            svalue={CountryId}
            placeholder="Select Country"
            link="Nishant/GetListOfAlgo?Type=9"
          ></CustomDropDown>
        </div>
        <div className="filtering" style={{ margin: 2, width: "20%" }}>
          <CustomDropDown
            passChildData={GetContinentId}
            disabled={DisableContinent}
            svalue={ContinentId}
            placeholder="Select Continent"
            link="Nishant/GetListOfAlgo?Type=10"
          ></CustomDropDown>
        </div>

        <div className="filtering" style={{ margin: 2, width: "20%" }}>
          <CustomDropDown
            passChildData={GetCurrencyCode}
            disabled={DisableCountry}
            svalue={CurrencyCode}
            placeholder="Select Currency"
            link="Nishant/GetListOfCurrency"
          ></CustomDropDown>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="filteringbtn" style={{ margin: 6, zIndex: 100 }}>
            <Button
              onClick={() => {
                let checks = "";
                if (non_esi_gsi && !new_formula) checks = "0";
                if (non_esi_gsi && new_formula) checks = "10";
                if (esi_gsi && !new_formula) checks += ",1,2,3,4";
                if (esi_gsi && new_formula) checks += ",11,12,13,14";
                if (intrest_debit) checks += ",5";
                if (internal_transfer) checks += ",6";
                if (sc_with_cancel) checks += ",7";
                if (bad_dept) checks += ",8";
                if (nudge_deduct) checks += ",9";
                if (marketing_salery_certification) checks += ",16,17,18";
                if (miscellaneous) checks += ",19";
                if (mo_bonus) checks += ",20";
                if (busy_weak) checks += ",21";
                if (trainer_cost) checks += ",22";

                if (checks.charAt(0) == ",") {
                  checks = checks.substring(1);
                }
                if (checks == "") {
                  checks = "0";
                }
                let js_start = new Date(sd);
                let js_end = new Date(ed);
                let sd_ =
                  js_start.getMonth() +
                  1 +
                  "/" +
                  js_start.getDate() +
                  "/" +
                  js_start.getFullYear();
                let ed_ =
                  js_end.getMonth() +
                  1 +
                  "/" +
                  js_end.getDate() +
                  "/" +
                  js_end.getFullYear();
                if (sd == "") {
                  sd_ = "07/01/2020";
                }
                if (ed == "") {
                  ed_ = "11/10/2020";
                }

                //alert(sd_ + '---' + ed_)
                //alert(checks)
                pandav_nr(month, checks, sd_, ed_);
                sales_manager_nr(month, checks);
                atm_nr(month, checks);
                freelancer_nr(month, checks);
                cce_nr(month, checks);
                asm_nr(month, checks);
              }}
              color="teal"
              content="Filter"
              icon="filter"
              labelPosition="right"
            />
          </div>
        </div>
      </div>

      {/* </div> */}

      {/* GRID SECTION */}
      <div style={{ overflow: "scroll" }}>
        {/* <div style={{ display: "flex", padding: 20 }}>
          <div style={{ padding: 20, width: "80%" }}>
            <div
              style={{
                flex: 1,
                width: "100%",
                display: "flex",
                position: "relative",
              }}
            >
              <span style={{ fontWeight: "bolder", fontSize: 18 }}>
                <h3>Pandavs</h3>
              </span>

              <span
                style={{
                  cursor: "pointer",
                  margin: 1,
                  display: pandav_loading ? "none" : "",
                }}
              >
                <Icon
                  onClick={() => {
                    set_month(month + 6);
                  }}
                  size="big"
                  color="teal"
                  name="caret left"
                />
              </span>

              <span
                style={{
                  cursor: "pointer",
                  margin: 1,
                  position: "absolute",
                  right: 5,
                  display: pandav_loading ? "none" : "",
                }}
              >
                <Icon
                  onClick={() => {
                    set_month(month - 6);
                  }}
                  size="big"
                  color="teal"
                  name="caret right"
                />
              </span>
            </div>

            <div
              style={{
                display: pandav_loading ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Loader active inline size="medium">
                getting data...
              </Loader>
            </div>

            <table
              id="conversion"
              className="table cvvtable"
              style={{ width: "100%", display: pandav_loading ? "none" : "" }}
            >
              <thead>
                <tr style={{ fontSize: 14, fontWeight: "bold" }}>
                  {header_pandav.map((key, index) => {
                    return <th key={index}>{key.toUpperCase()}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {pandav_data_list.map((row, ii) => (
                  <tr key={ii}>
                    {Object.values(row).map((rowValue, i) => {
                      if (i == 0) {
                        return (
                          <td key={i} className="text-center">
                            <b>{rowValue}</b>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            onClick={() => {
                              cell_onclick(
                                pandav_data_list[ii].Manager,
                                header_pandav[i],
                                "pandav"
                              ); //cell_onclick(data_list[ii].cce, header[i])
                            }}
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            key={i}
                          >
                            <b>
                              {rowValue == null
                                ? 0
                                : parseInt(rowValue).toLocaleString("en-IN")}
                            </b>{" "}
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}
        {showGraph && (
          <div>
            <LineGraph data={generateChartData()} />
          </div>
        )}

        <div style={{ display: "flex", padding: 20 }}>
          <div style={{ padding: 20, width: "80%" }}>
            <div
              style={{
                flex: 1,
                width: "100%",
                display: "flex",
                position: "relative",
              }}
            >
              <span style={{ fontWeight: "bolder", fontSize: 18 }}>
                <h3>Sales Manager</h3>
              </span>

              <span
                style={{
                  cursor: "pointer",
                  margin: 1,
                  display: pandav_loading ? "none" : "",
                }}
              >
                <Icon
                  onClick={() => {
                    set_month(month + 6);
                    setShowGraph(false);
                  }}
                  size="big"
                  color="teal"
                  name="caret left"
                />
              </span>

              <span
                style={{
                  cursor: "pointer",
                  margin: 1,
                  position: "absolute",
                  right: 5,
                  display: pandav_loading ? "none" : "",
                }}
              >
                <Icon
                  onClick={() => {
                    set_month(month - 6);
                    setShowGraph(false);
                  }}
                  size="big"
                  color="teal"
                  name="caret right"
                />
              </span>
              <div
                style={{
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                }}
              ></div>
            </div>
            <div
              style={{
                display: sales_loading ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Loader active inline size="medium">
                getting data...
              </Loader>
            </div>
            <table
              id="conversion"
              className="table cvvtable"
              style={{ width: "100%", display: sales_loading ? "none" : "" }}
            >
              <thead>
                <tr style={{ fontSize: 14, fontWeight: "bold" }}>
                  {header_sales_manager.map((key, index) => {
                    return <th key={index}>{key.toUpperCase()}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {sales_manager_data_list.map((row, ii) => (
                  <tr key={ii}>
                    {Object.values(row).map((rowValue, i) => {
                      if (i == 0) {
                        return (
                          <td key={i} className="text-center">
                            <b>
                              <span style={{ marginRight: 5, flex: 1 }}>
                                {rowValue}
                              </span>
                              <Button
                                onClick={() => {
                                  toggleGraph(true);
                                  setModalOpen(true);
                                }}
                                primary
                                style={{
                                  fontSize: "inherit",
                                  padding: 0,
                                }}
                              >
                                Graph
                              </Button>
                            </b>
                          </td>
                        );
                      } else if (i <= 7) {
                        const dynamicIndex =
                          header_sales_manager.length != 6 ? i : i + 2;
                        const dynamicValue =
                          sales_manager_data_list_last_year[ii] &&
                          Object.values(sales_manager_data_list_last_year[ii])[
                          dynamicIndex
                          ];
                        let rowStyle = {};
                        if (rowValue < 0 || rowValue < dynamicValue) {
                          rowStyle = { color: "red" };
                        } else if (
                          rowValue > dynamicValue * 1.2 &&
                          dynamicValue !== 0
                        ) {
                          rowStyle = { color: "goldenrod" };
                        }
                        return (
                          <td
                            onClick={() => {
                              cell_onclick(
                                sales_manager_data_list[ii].Manager,
                                header_sales_manager[i],
                                "sales_manager"
                              );
                              //alert(sales_manager_data_list[ii].Manager + '--' + header_sales_manager[i])
                            }}
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            key={i}
                          >
                            <>
                              {isDisplaySameMonthLastYear && (
                                <b style={rowStyle}>
                                  {rowValue == null
                                    ? 0
                                    : parseInt(rowValue).toLocaleString(
                                      "en-IN"
                                    )}
                                </b>
                              )}
                              {!isDisplaySameMonthLastYear && (
                                <b>
                                  {rowValue == null
                                    ? 0
                                    : parseInt(rowValue).toLocaleString(
                                      "en-IN"
                                    )}
                                </b>
                              )}
                              <br />
                              {isDisplaySameMonthLastYear && (
                                <b>
                                  {dynamicValue == null
                                    ? 0
                                    : parseInt(dynamicValue).toLocaleString(
                                      "en-IN"
                                    )}
                                </b>
                              )}
                            </>
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {showFreelancerGraph && (
          <div>
            <LineGraph data={generateFreelancerChartData()} />
          </div>
        )}
        <div style={{ display: "flex", padding: 20 }}>
          <div style={{ padding: 20, width: "80%" }}>
            <div
              style={{
                flex: 1,
                width: "100%",
                display: "flex",
                position: "relative",
              }}
            >
              <span style={{ fontWeight: "bolder", fontSize: 18 }}>
                <h3>Freelancer</h3>
              </span>

              <span
                style={{
                  cursor: "pointer",
                  margin: 1,
                  display: pandav_loading ? "none" : "",
                }}
              >
                <Icon
                  onClick={() => {
                    set_month(month + 6);
                    setShowFreelancerGraph(false);
                  }}
                  size="big"
                  color="teal"
                  name="caret left"
                />
              </span>

              <span
                style={{
                  cursor: "pointer",
                  margin: 1,
                  position: "absolute",
                  right: 5,
                  display: pandav_loading ? "none" : "",
                }}
              >
                <Icon
                  onClick={() => {
                    set_month(month - 6);
                    setShowFreelancerGraph(false);
                  }}
                  size="big"
                  color="teal"
                  name="caret right"
                />
              </span>
            </div>

            <div
              style={{
                display: freelancer_loading ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Loader active inline size="medium">
                getting data...
              </Loader>
            </div>

            <table
              id="conversion"
              className="table cvvtable"
              style={{
                width: "100%",
                display: freelancer_loading ? "none" : "",
              }}
            >
              <thead>
                <tr style={{ fontSize: 14, fontWeight: "bold" }}>
                  {header_freelancer.map((key, index) => {
                    return <th key={index}>{key.toUpperCase()}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {freelancer_data_list.map((row, ii) => (
                  <tr key={ii}>
                    {Object.values(row).map((rowValue, i) => {
                      if (i == 0) {
                        return (
                          <td key={i} className="text-center">
                            <span style={{ marginRight: 5, flex: 1 }}>
                              {rowValue}
                            </span>
                            <Button
                              onClick={() => {
                                toggleFreelancerGraph(true);
                                setModalOpen(true);
                              }}
                              primary
                              style={{
                                fontSize: "inherit",
                                padding: 0,
                              }}
                            >
                              Graph
                            </Button>
                          </td>
                        );
                      } else if (i <= 7) {
                        const dynamicIndex =
                          header_freelancer.length != 6 ? i : i + 2;
                        const dynamicValue =
                          freelancer_data_list_last_year[ii] &&
                          Object.values(freelancer_data_list_last_year[ii])[
                          dynamicIndex
                          ];
                        let rowStyle = {};
                        if (rowValue < 0 || rowValue < dynamicValue) {
                          rowStyle = { color: "red" };
                        } else if (
                          rowValue > dynamicValue * 1.2 &&
                          dynamicValue !== 0
                        ) {
                          rowStyle = { color: "goldenrod" };
                        }
                        return (
                          <td
                            onClick={() => {
                              cell_onclick(
                                freelancer_data_list[ii].Manager,
                                header_freelancer[i],
                                "freelancer"
                              );
                              //alert(sales_manager_data_list[ii].Manager + '--' + header_sales_manager[i])
                            }}
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            key={i}
                          >
                            <>
                              {isDisplaySameMonthLastYear && (
                                <b style={rowStyle}>
                                  {rowValue == null
                                    ? 0
                                    : parseInt(rowValue).toLocaleString(
                                      "en-IN"
                                    )}
                                </b>
                              )}
                              {!isDisplaySameMonthLastYear && (
                                <b>
                                  {rowValue == null
                                    ? 0
                                    : parseInt(rowValue).toLocaleString(
                                      "en-IN"
                                    )}
                                </b>
                              )}
                              <br />
                              {isDisplaySameMonthLastYear && (
                                <b>
                                  {dynamicValue == null
                                    ? 0
                                    : parseInt(dynamicValue).toLocaleString(
                                      "en-IN"
                                    )}
                                </b>
                              )}
                            </>
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* <div style={{ display: "flex", padding: 20 }}>
          <div style={{ padding: 20, width: "80%" }}>
            <div
              style={{
                flex: 1,
                width: "100%",
                display: "flex",
                position: "relative",
              }}
            >
              <span style={{ fontWeight: "bolder", fontSize: 18 }}>
                <h3>ATM</h3>
              </span>

              <span
                style={{
                  cursor: "pointer",
                  margin: 1,
                  display: pandav_loading ? "none" : "",
                }}
              >
                <Icon
                  onClick={() => {
                    set_month(month + 6);
                  }}
                  size="big"
                  color="teal"
                  name="caret left"
                />
              </span>

              <span
                style={{
                  cursor: "pointer",
                  margin: 1,
                  position: "absolute",
                  right: 5,
                  display: pandav_loading ? "none" : "",
                }}
              >
                <Icon
                  onClick={() => {
                    set_month(month - 6);
                  }}
                  size="big"
                  color="teal"
                  name="caret right"
                />
              </span>
            </div>
            <div
              style={{
                display: atm_loading ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Loader active inline size="medium">
                getting data...
              </Loader>
            </div>

            <table
              id="conversion"
              className="table cvvtable"
              style={{ width: "100%", display: atm_loading ? "none" : "" }}
            >
              <thead>
                <tr style={{ fontSize: 14, fontWeight: "bold" }}>
                  {header_atm.map((key, index) => {
                    return <th key={index}>{key.toUpperCase()}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {atm_data_list.map((row, ii) => (
                  <tr key={ii}>
                    {Object.values(row).map((rowValue, i) => {
                      if (i == 0) {
                        return (
                          <td key={i} className="text-center">
                            <b>{rowValue}</b>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            onClick={() => {
                              cell_onclick(
                                atm_data_list[ii].Manager,
                                header_atm[i],
                                "atm"
                              );
                              //alert(sales_manager_data_list[ii].Manager + '--' + header_sales_manager[i])
                            }}
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            key={i}
                          >
                            <b>
                              {rowValue == null
                                ? 0
                                : parseInt(rowValue).toLocaleString("en-IN")}
                            </b>
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}
        {showManagerGraph && (
          <div>
            <LineGraph data={generateManagerChartData()} />
          </div>
        )}
        <div style={{ display: "flex", padding: 20 }}>
          <div style={{ padding: 20, width: "80%" }}>
            <div
              style={{
                flex: 1,
                width: "100%",
                display: "flex",
                position: "relative",
              }}
            >
              <span style={{ fontWeight: "bolder", fontSize: 18 }}>
                <h3>Managers</h3>
              </span>

              <span
                style={{
                  cursor: "pointer",
                  margin: 1,
                  display: pandav_loading ? "none" : "",
                }}
              >
                <Icon
                  onClick={() => {
                    set_month(month + 6);
                    setShowManagerGraph(false);
                  }}
                  size="big"
                  color="teal"
                  name="caret left"
                />
              </span>

              <span
                style={{
                  cursor: "pointer",
                  margin: 1,
                  position: "absolute",
                  right: 5,
                  display: pandav_loading ? "none" : "",
                }}
              >
                <Icon
                  onClick={() => {
                    set_month(month - 6);
                    setShowManagerGraph(false);
                  }}
                  size="big"
                  color="teal"
                  name="caret right"
                />
              </span>
            </div>

            <div
              style={{
                display: asm_loading ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Loader active inline size="medium">
                getting data...
              </Loader>
            </div>

            <table
              id="conversion"
              className="table cvvtable"
              style={{ width: "100%", display: asm_loading ? "none" : "" }}
            >
              <thead>
                <tr style={{ fontSize: 14, fontWeight: "bold" }}>
                  {header_asm.map((key, index) => {
                    return <th key={index}>{key.toUpperCase()}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {asm_data_list.map((row, ii) => (
                  <tr key={ii}>
                    {Object.values(row).map((rowValue, i) => {
                      if (i == 0) {
                        return (
                          <td key={i} className="text-center">
                            <span style={{ marginRight: 5, flex: 1 }}>
                              {rowValue}
                            </span>
                            <Button
                              onClick={() => {
                                toggleManagerGraph();
                                setModalOpen(true);
                                setCellManagerName(asm_data_list[ii].Manager);
                              }}
                              primary
                              style={{
                                fontSize: "inherit",
                                padding: 0,
                              }}
                            >
                              Graph
                            </Button>
                          </td>
                        );
                      } else if (i <= 7) {
                        const dynamicIndex = header_asm.length != 6 ? i : i + 2;
                        const dynamicValue =
                          asm_data_list_last_year[ii] &&
                          Object.values(asm_data_list_last_year[ii])[
                          dynamicIndex
                          ];
                        let rowStyle = {};
                        if (rowValue < 0 || rowValue < dynamicValue) {
                          rowStyle = { color: "red" };
                        } else if (
                          rowValue > dynamicValue * 1.2 &&
                          dynamicValue !== 0
                        ) {
                          rowStyle = { color: "goldenrod" };
                        }
                        return (
                          <td
                            onClick={() => {
                              cell_onclick(
                                asm_data_list[ii].Manager,
                                header_asm[i],
                                "asm"
                              );
                              //alert(sales_manager_data_list[ii].Manager + '--' + header_sales_manager[i])
                            }}
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            key={i}
                          >
                            <>
                              {isDisplaySameMonthLastYear && (
                                <b style={rowStyle}>
                                  {rowValue == null
                                    ? 0
                                    : parseInt(rowValue).toLocaleString(
                                      "en-IN"
                                    )}
                                </b>
                              )}
                              {!isDisplaySameMonthLastYear && (
                                <b>
                                  {rowValue == null
                                    ? 0
                                    : parseInt(rowValue).toLocaleString(
                                      "en-IN"
                                    )}
                                </b>
                              )}
                              <br />
                              {isDisplaySameMonthLastYear && (
                                <b>
                                  {dynamicValue == null
                                    ? 0
                                    : parseInt(dynamicValue).toLocaleString(
                                      "en-IN"
                                    )}
                                </b>
                              )}
                            </>
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {showCceGraph && (
          <div>
            <LineGraph data={generateCceData()} />
          </div>
        )}
        <div style={{ display: "flex", padding: 20 }}>
          <div style={{ padding: 20, width: "80%" }}>
            <div
              style={{
                flex: 1,
                width: "100%",
                display: "flex",
                position: "relative",
              }}
            >
              <span style={{ fontWeight: "bolder", fontSize: 18 }}>
                <h3>CCE</h3>
              </span>

              <span
                style={{
                  cursor: "pointer",
                  margin: 1,
                  display: pandav_loading ? "none" : "",
                }}
              >
                <Icon
                  onClick={() => {
                    set_month(month + 6);
                    setShowCceGraph(false);
                  }}
                  size="big"
                  color="teal"
                  name="caret left"
                />
              </span>

              <span
                style={{
                  cursor: "pointer",
                  margin: 1,
                  position: "absolute",
                  right: 5,
                  display: pandav_loading ? "none" : "",
                }}
              >
                <Icon
                  onClick={() => {
                    set_month(month - 6);
                    setShowCceGraph(false);
                  }}
                  size="big"
                  color="teal"
                  name="caret right"
                />
              </span>
            </div>
            <div
              style={{
                display: cce_loading ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Loader active inline size="medium">
                getting data... Please wait
              </Loader>
            </div>

            <table
              id="conversion"
              className="table cvvtable"
              style={{ width: "100%", display: cce_loading ? "none" : "" }}
            >
              <thead>
                <tr style={{ fontSize: 14, fontWeight: "bold" }}>
                  {header_cce.map((key, index) => {
                    return <th key={index}>{key.toUpperCase()}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {cce_data_list.map((row, ii) => (
                  <tr key={ii}>
                    {Object.values(row).map((rowValue, i) => {
                      if (i == 0) {
                        return (
                          <td key={i} className="text-center">
                            <span style={{ marginRight: 5, flex: 1 }}>
                              {rowValue}
                            </span>
                            <Button
                              onClick={() => {
                                toggleCceGraph();
                                setModalOpen(true);
                                setCellCceManagerName(
                                  cce_data_list[ii].Manager
                                );
                                setCellCceName(cce_data_list[ii].CCE);
                              }}
                              primary
                              style={{
                                fontSize: "inherit",
                                padding: 0,
                              }}
                            >
                              Graph
                            </Button>
                          </td>
                        );
                      } else if (i == 1) {
                        return (
                          <td
                            onClick={() => {
                              cell_onclick(
                                cce_data_list[ii].CCE,
                                header_cce[i],
                                "cce"
                              );
                              //alert(sales_manager_data_list[ii].Manager + '--' + header_sales_manager[i])
                            }}
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            key={i}
                          >
                            {/* <b dangerouslySetInnerHTML>{(rowValue == null) ? 0 : rowValue}</b> */}
                            <b dangerouslySetInnerHTML={{ __html: rowValue }} />
                          </td>
                        );
                      } else if (isDisplaySameMonthLastYear && i <= 8) {
                        const dynamicIndex = header_cce.length != 7 ? i : i + 3;
                        const dynamicValue =
                          cce_data_list_last_year[ii] &&
                          Object.values(cce_data_list_last_year[ii])[
                          dynamicIndex
                          ];

                        const dynamicValueAsNumber = dynamicValue
                          ? parseFloat(dynamicValue.replace(/,/g, "")) || 0
                          : 0;
                        const rowValueAsNumber =
                          parseFloat(rowValue.replace(/,/g, "")) || 0;
                        let rowStyle = {};
                        if (
                          rowValueAsNumber < 0 ||
                          rowValueAsNumber < dynamicValueAsNumber
                        ) {
                          rowStyle = { color: "red" };
                        } else if (
                          rowValueAsNumber > dynamicValueAsNumber * 1.2 &&
                          dynamicValueAsNumber !== 0
                        ) {
                          rowStyle = { color: "goldenrod" };
                        }
                        return (
                          <td
                            onClick={() => {
                              cell_onclick(
                                cce_data_list[ii].CCE,
                                header_cce[i],
                                "cce"
                              );
                              //alert(sales_manager_data_list[ii].Manager + '--' + header_sales_manager[i])
                            }}
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            key={i}
                          >
                            {/* <b dangerouslySetInnerHTML>{(rowValue == null) ? 0 : rowValue}</b> */}
                            <>
                              <b style={rowStyle}>{rowValue}</b>
                              <br />
                              <b>{dynamicValue}</b>
                            </>
                          </td>
                        );
                      } else if (i <= 8) {
                        return (
                          <td
                            onClick={() => {
                              cell_onclick(
                                cce_data_list[ii].CCE,
                                header_cce[i],
                                "cce"
                              );
                              //alert(sales_manager_data_list[ii].Manager + '--' + header_sales_manager[i])
                            }}
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            key={i}
                          >
                            {/* <b dangerouslySetInnerHTML>{(rowValue == null) ? 0 : rowValue}</b> */}
                            <b dangerouslySetInnerHTML={{ __html: rowValue }} />
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
